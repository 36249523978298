<!-- 基础form表单 -->

<template>
  <el-row :gutter="currentFormAttrs.gutter">
    <el-form
      class="sz-form"
      v-bind="currentFormAttrs"
      ref="baseForm"
      @submit.native.prevent
    >
      <template v-for="(it, ids) in currentFormList">
        <el-col v-bind="colAttrs(it)" :key="`${ids}${it.prop}`">
          <el-form-item v-bind="filterItem(it)">
          <!-- 改写标题 -->
          <div slot="label" class="el-form-item__label_new" v-if="filterItem(it).label">
            <span>{{filterItem(it).label}}</span>
            <span v-if="filterItem(it).showTip" style="position:absolute">
            <el-tooltip effect="dark" placement="top-start">
              <div slot="content">
                {{filterItem(it).showTip}}
              </div>
              <i class="iconfont icontanhao"></i>
            </el-tooltip>
            </span>
            <span v-if="filterItem(it).showBtn" style="position:absolute">
              <icon-button  @click.prevent="handleIconClick(it.prop,it.attrs.textValue)"
            :content="it.attrs.btnText"
            :icon="it.attrs.iconfont"></icon-button>
            <div class="showTipText">{{it.attrs.showTipText}}</div>
            </span>
            </div>
            <component
              :is="it.tag"
              v-bind="{...it.attrs, keyIndex: keyIndex}"
              v-on="it.on"
              v-model="currentFormAttrs.model[it.prop]"
            ></component>
          </el-form-item>
        </el-col>
      </template>
      <!-- 按钮 -->
    </el-form>
    <div
      class="btns"
      :class="{ isTopStyle: currentFormAttrs.labelPosition === 'top' }"
      v-if="showBtns"
    >
      <slot name="btn_before"></slot>
      <base-button
        type="primary"
        @click.prevent="handleFilter"
        label="查询"
        v-if="btnSerch"
      ></base-button>
      <base-button
        @click.prevent="handleClearParams"
        label="重置"
        type="default"
        v-if="btnReset"
      ></base-button>
      <slot name="btn_after"></slot>
    </div>
  </el-row>
</template>

<script>
import Input from '@/components/input'
import IconButton from '../button/icon-button/icon-button.vue'
import baseButton from '../button/base-button/base-button.vue'
export const DEFAULT_COLS = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }

// 设置组件传入的参数
const normalizeFormAttrs = (config) => {
  if (!config.model) config.model = {}
  if (!config.labelPosition) config.labelPosition = 'top'
  if (config.labelPosition === 'top' && !config.gutter) {
    config.gutter = 20
  } else {
    config.labelWidth = config.labelWidth || '150px'
  }
  return config
}
// 设置表单默认的参数配置
const normalizeFormItem = (nums) => {
  const res = []
  nums.forEach((it) => {
    if (it.isHidden) {
      return
    }
    if (!it.tag) it.tag = Input
    if (!it.on) it.on = Object.create(null)
    res.push(it)
  })
  return res
}

export default {
  name: 'baseForm',
  components: { baseButton, IconButton },
  props: {
    formAttrs: {
      type: Object,
      default: () => ({})
    },
    componentList: {
      type: Array,
      default: () => []
    },
    showBtns: {
      type: Boolean,
      default: true
    },
    btnSerch: {
      type: Boolean,
      default: true
    },
    btnReset: {
      type: Boolean,
      default: true
    },
    keyIndex: {
      type: Number,
      default: 0
    }
  },

  computed: {
    currentFormAttrs () {
      // console.log(normalizeFormAttrs(this.formAttrs))
      return normalizeFormAttrs(this.formAttrs)
    },
    currentFormList () {
      return normalizeFormItem(this.componentList)
    },
    btnCols () {
      if (this.currentFormList.length) {
        return this.currentFormList[0].col
          ? this.currentFormList[0].span
          : DEFAULT_COLS
      }
      return DEFAULT_COLS
    }
  },

  methods: {
    handleIconClick (prop, value) {
      console.log(prop, value)
      if (this.formAttrs.model[prop]) {
        this.$confirm('是否用示例覆盖?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$set(this.formAttrs.model, prop, value)
          // this.$emit(this.formData.shareholderResultValidInfo, 'validRemark', Str)
        }).catch(() => {

        })
      } else {
        this.$set(this.formAttrs.model, prop, value)
      }
    },
    colAttrs (it) {
      const { span } = it
      if (typeof span !== 'undefined') {
        return {
          span
        }
      } else {
        return it.cols || DEFAULT_COLS
      }
    },
    handleClearParams () {
      this.$emit('clearParams')
      this.clearValidate()
      // this.$nextTick(() => {
      //   this.handleFilter()
      // })
    },
    async handleFilter () {
      // console.log('handleFilter')
      try {
        await this.validate()
        this.$emit('handleFilter')
      } catch (error) {
        console.log('表单有误', error)
      }
    },
    filterItem (item) {
      const { attrs, on, tag, span, ...rest } = item

      return rest
    },
    validate (...arg) {
      return this.$refs.baseForm.validate(...arg)
    },
    validateField (...arg) {
      return this.$refs.baseForm.validateField(...arg)
    },
    resetFields (...arg) {
      return this.$refs.baseForm.resetFields(...arg)
    },
    clearValidate (...arg) {
      return this.$refs.baseForm.clearValidate(...arg)
    }
  }
}
</script>

<style lang="scss" src="./base-form.scss" scoped></style>
