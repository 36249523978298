import { formatRoutePath } from '@/router/router-check'

export default {
  namespaced: true,
  state: {
    menus: [],
    menusList: [],
    menuIdMap: Object.create(null),
    openFlag: false,
    dictMaps: {},
    fullScreenFlag: false,
    tagsCache: [],
    freshFlag: true,
    authPerms: {}
  },
  actions: {
    changeOpenFlag: ({ commit, state }) => {
      commit('setOpenFlag', !state.openFlag)
    },
    changeScreen: ({ commit, state }) => {
      commit('setScreenFlag', !state.fullScreenFlag)
    },
    changeCaches: ({ commit }, caches) => {
      commit('changeCaches', caches)
    },
    changeFresh: ({ commit }, flag) => {
      commit('changeFresh', flag)
    }
  },
  mutations: {
    setOpenFlag: (state, flag) => {
      state.openFlag = flag
    },
    setScreenFlag: (state, flag) => {
      state.fullScreenFlag = flag
    },
    setMenusList: (state, menusLists) => {
      state.menusList = menusLists
    },
    setMenus: (state, menus) => {
      state.menus = menus
    },
    setDictMaps: (state, dicts) => {
      state.dictMaps = buildDictMaps(dicts)
    },
    changeCaches: (state, caches) => {
      state.tagsCache = caches
    },
    changeFresh: (state, flag) => {
      state.freshFlag = flag
    },
    setPerms: (state, menus) => {
      const map = Object.create(null)
      const menuIdMap = Object.create(null)
      menus.forEach(it => {
        const { opAuthorityDTOList = [], path, menuId } = it
        const authCodes = opAuthorityDTOList.map(it => it.operationCode)
        map[path] = authCodes
        menuIdMap[formatRoutePath(path)] = menuId
      })
      state.authPerms = map
      state.menuIdMap = menuIdMap
    }
  }
}

/**
 * 字典通过字典code 分组
 * 新加排序
 * @param {*} data
 * @returns
 */
export const buildDictMaps = (data = []) => {
  const res = Object.create(null)
  data.sort(sortBySortNo).forEach((it) => {
    const { dictTypeCode } = it
    if (!res[dictTypeCode]) {
      res[dictTypeCode] = [it]
    } else {
      res[dictTypeCode].push(it)
    }
  })
  return res
}

const sortBySortNo = (a, b) => {
  return a.sortNo - b.sortNo
}
