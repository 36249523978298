
<script>
import Input from '@/lib/element/input'
import { EmojiReg } from '@/config/reg-config'

// 罗马数字 数字 中文 英文 特殊字符
const inputReg =
  /^([\u2160-\u216B\u2170-\u2179\u4E00-\u9FA5A-Za-z0-9]|[`~!@#$%^&*()_+<>?:"{}\-,.\\/;'[\]]|[·！#￥（——）-：；“”‘、，□■•·|《。》？、【】[\]])*$/

// textarea 输入校验 新增 空格 换行
const textareaReg =
  /^([\u2160-\u216B\u2170-\u2179\u4E00-\u9FA5A-Za-z0-9]|[`~!@#$%^&*()_+<>?:"{}\-,.\\/;'[\]]|[·！#￥（——）-：……；“”‘、，□■•·|《。》？、【】[\]]|\s)*$/

export const validateInput = (val, isInput, restrict) => {
  const reg = isInput ? inputReg : textareaReg
  if (!reg.test(val) && restrict) {
    return false
  }
  return true
}

export default {
  name: 'el-input',
  components: {
    'base-input': Input
  },
  props: {
    value: {},
    restrict: { // 根据正则限制输入框
      type: Boolean,
      default: true // false 只限制表情
    }
  },
  watch: {
    value (val) {
      if (val === -999 || val === '-999') {
        this.$emit('input', '')
      }
    }
  },
  computed: {
    currentListeners () {
      return Object.assign({}, this.$listeners, {
        input: this.handleInput
      })
    }
  },
  methods: {
    handleInput (value) {
      if (!this.restrict) {
        value = value.replace(EmojiReg, '')
      }
      if (!this.$attrs.type) {
        if (this.$attrs.maxlength) {
          if (value.length > this.$attrs.maxlength || !validateInput(value, false, this.restrict)) {
            return
          }
        } else {
          if (value.length > 100 || !validateInput(value, true, this.restrict)) {
            return
          }
        }
      } else if (this.$attrs.type === 'textarea') {
        if (this.$attrs.maxlength) {
          if (value.length > this.$attrs.maxlength || !validateInput(value, false, this.restrict)) {
            return
          }
        } else {
          if (value.length > 2000 || !validateInput(value, false, this.restrict)) {
            return
          }
        }
      } else if (this.$attrs.type === 'memo') {
        if (value.length > 2000 || !validateInput(value, false, this.restrict)) {
          return
        }
      } else if (this.$attrs.type === 'bigTextarea') {
        if (value.length > 2000 || !validateInput(value, false, this.restrict)) {
          return
        }
      }
      this.$emit('input', value)
    }
  },
  render (h) {
    const children = Object.keys(this.$slots).map((slot) =>
      h('template', { slot }, this.$slots[slot])
    )
    const { currentListeners, $attrs } = this
    return h(
      'base-input',
      {
        attrs: {
          ...$attrs,
          value: this.value
        },
        on: currentListeners,
        scopedSlots: this.$scopedSlots
      },
      children
    )
  }
}
</script>
