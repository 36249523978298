const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 资金方管理
  {
    path: '/fundManagement/fundmanagement',
    name: 'fundmanagement',
    component: _import('fund-management/fund-management/fund-management'),
    meta: {
      cache: false
    }
  },
  {
    path: '/addFund',
    name: 'addFund',
    component: _import('fund-management/fund-management/add-fund') // 新增资金方
  }
]
