import store from '@/store'
export * from './permission'
export * from './menuHelp'

/**
 *  接口请求头信息
 * @returns {Authorization,token,tenantId}
 */
export const getHeaders = () => {
  const {
    user: { token, tenantId, clientId, operateId }
  } = store.state
  let res = {}
  if (token) {
    res = {
      Authorization: 'Bearer ' + token,
      token: token,
      operatorId: operateId || '',
      tenantId: tenantId,
      client: clientId
    }
  }
  return res
}
