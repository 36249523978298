<template>
  <base-dialog
    :visible.sync="visible"
    class="resetDialog"
    @submit="submit"
    :title="title"
    width="600px"
  >
    <base-form
      ref="passwordRef"
      :componentList="componentList"
      :showBtns="false"
      :formAttrs="{
        model: formData,
      }"
    ></base-form>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { resetPwd } from '@/api/sys/user'
import { validatePasswordInput } from '../utils/validate'
export default {
  components: { baseDialog, BaseForm },
  props: {
    title: {
      type: String,
      default: '重置密码'
    }
  },
  data () {
    return {
      visible: false,
      formData: {},
      componentList: [
        {
          label: '旧密码',
          prop: 'oldPassword',
          span: 24,
          rules: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { validator: validatePasswordInput, trigger: ['blur', 'change'] }
          ],
          // on: {
          //   blur: this.handleValidate
          // },
          attrs: {
            type: 'password',
            error: '',
            placeholder: '请输入',
            maxlength: '15'
          }
        },
        {
          label: '新密码',
          prop: 'newPassword',
          span: 24,
          rules: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { validator: validatePasswordInput, trigger: ['blur', 'change'] },
            { validator: this.validateNewPassword, trigger: ['blur', 'change'] }
          ],
          attrs: {
            type: 'password',
            error: '',
            placeholder: '请输入',
            maxlength: '15'

          }
        },
        {
          label: '确认密码',
          prop: 'rePassword',
          span: 24,
          rules: [
            { required: true, message: '请再次确认新密码', trigger: 'blur' },
            { validator: this.validateRePassword, trigger: ['blur', 'change'] }
          ],
          attrs: {
            type: 'password',
            placeholder: '请输入',
            maxlength: '15'

          }
        }
      ]
    }
  },
  methods: {
    open (data) {
      this.visible = true
      this.registerClear()
      this.formData.userId = data.userId
    },
    handleValidate () {
      this.$refs.passwordRef.validateField('newPassword')
    },
    /**
     * 关闭时处理重置数据逻辑
     */
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        this.formData = Object.create(null)
        this.$nextTick(() => {
          this.$refs.passwordRef.clearValidate()
        })
        listen()
      })
    },
    async submit () {
      try {
        await this.$refs.passwordRef.validate()
        resetPwd(this.formData)
          .then(() => {
            this.success('修改成功')
            this.$emit('refresh')
            this.visible = false
          })
          .catch(() => {})
      } catch (error) {
        this.warning('请完善表格输入')
      }
    },
    validateNewPassword (r, v, c) {
      if (this.formData.oldPassword !== v) {
        c()
      } else {
        c(new Error('新密码不能与旧密码一样'))
      }
    },
    validateRePassword (r, v, c) {
      if (v && v !== this.formData.newPassword) {
        c(new Error('两次密码输入不一致'))
      } else {
        c()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resetDialog {
  /deep/ .el-dialog {
    margin-top: 12vh !important;
    height: 50vh;
  }
  /deep/ .el-dialog__body {
    height: calc(50vh - 100px) !important;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
</style>
