<!-- 基础弹框 -->
<template>
  <el-dialog
    v-bind="currentAttrs"
    v-on="$listeners"
    class="dialog"
    :class="{ autoHeight: autoHeight,isMobile:isMobile }"
    :title="title"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <!-- 头部插槽 -->
    <template v-if="$slots['title']" slot="title">
      <slot name="title"></slot>
    </template>
    <!-- 内容区域 -->
    <slot></slot>

    <template slot="footer">
      <template v-if="showFooter">
        <base-button
          @click="$emit('submit')"
          v-if="showSubmit"
          :label="submitText"
        ></base-button>
        <base-button
          @click="dialogVisible = false"
          :label="canleText"
          type="default"
        ></base-button>
        <slot name="footer-self"></slot>
      </template>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
import BaseButton from '../button/base-button/base-button.vue'
export default {
  name: 'baseDialog',
  components: { BaseButton },
  props: {
    title: String,
    autoHeight: {
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    submitText: {
      type: String,
      default: '提交'
    },
    canleText: {
      type: String,
      default: '关闭'
    },
    visible: Boolean
  },
  data () {
    return {
      isMobile: false
    }
  },
  watch: {
    dialogVisible (val) {
      if (val) {
        this.$nextTick(() => {
          this.browserRedirect()
        })
      }
    }
  },
  computed: {

    currentAttrs () {
      return {
        width: '60%',
        'modal-append-to-body': true,
        'close-on-click-modal': false,
        center: true,
        ...this.$attrs
      }
    },
    dialogVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },

  mounted () {

  },

  methods: {
    browserRedirect () {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.isMobile = true
      }
    }
  }
}
</script>
<style lang="scss" src="./base-dialog.scss" scoped></style>
