<template>
  <el-select
    v-bind="$attrs"
    v-on="currentListeners"
    style="width: 100%"
    :allow-create="allowCreate"
    :default-first-option="defaultFirstOption"
    popper-class="selsectbox"
    @visible-change="visibleChange"
    @focus="focus"
    ref='baseSelect'
    :clearable="clearable"
    :filter-method="filterMethod"
  >
    <el-option
      v-for="(v, index) of renderOption"
      :key="v[keyId]"
      :label="v[keyName]"
      :value="v[keyId]"
      :disabled="optionDisabled(v)"
      ><el-tooltip
        class="item"
        effect="dark"
        :content="v[keyName]"
        placement="top"
        :disabled="disabledArr.length > 0 ? disabledArr[index] : true"
      >
        <div ref="valueBox" >{{ v[keyName] }}</div>
      </el-tooltip></el-option
    >
  </el-select>
</template>

<script>
export default {
  name: 'base-select',
  inheritAttrs: false,
  props: {
    max: {
      type: Number, // 最大显示多少条
      default: 50
    },
    allowCreate: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    HideTips: {
      // 是否隐藏文字提示
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedField: {
      type: Array,
      default: () => ['id', 'name']
    },
    optionDisabled: {
      type: Function,
      default: () => {
        return false
      }
    },
    optionsFilter: {
      type: Function,
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    }

  },
  watch: {
    '$attrs.value': {
      handler (val) {
        if (!val) {
          this.filterMethod(val)
        }
      },
      deep: true
    }
  },
  data () {
    return {
      renderOption: [],
      disabledArr: []
    }
  },
  mounted () {
    this.init()
  },
  create () {
    this.init()
  },
  methods: {
    async init () {
      this.filterMethod()
    },
    filterMethod (label) {
      let arr = []
      let options = this.filterDouble(this.options)
      if (typeof this.optionsFilter === 'function') {
        options = this.optionsFilter(options)
      }
      let max = this.max
      if (Array.isArray(this.$attrs.value)) {
        max = 999999999999999999999999999999999
      }
      arr = options.filter(item => item[this.selectedField[1]].includes(label))
      if (!label) { arr = options.slice(0, max) }
      this.renderOption = arr.length > max ? arr.slice(0, max) : arr
    },
    // addValueOptions () {
    //   let options = this.filterDouble(this.options)
    //   if (typeof this.optionsFilter === 'function') {
    //     options = this.optionsFilter(options)
    //   }
    //   if (this.$attrs.value) {
    //     const target = options.find((item) => { // 从大option中找到当前条
    //       return item[this.selectedField[1]] === this.$attrs.value
    //     })
    //     if (target) { // 将当前条与小option比对，没有则加入
    //       if (this.renderOption.every(item => item.value !== target.value)) {
    //         this.renderOption.unshift(target)
    //       }
    //     }
    //   }
    // },
    // newOptionFilter (query = '') {
    //   let options = this.filterDouble(this.options)
    //   if (typeof this.optionsFilter === 'function') {
    //     options = this.optionsFilter(options)
    //   }

    //   const arr = options.filter((item) => {
    //     console.log(item[this.selectedField[1]].includes(query), query)
    //     return item[this.selectedField[1]].includes(query) // || item[this.selectedField[0]].includes(query)
    //   })
    //   if (arr.length > this.max) {
    //     this.renderOption = arr.slice(0, this.max)
    //     this.addFilterOptions(query)
    //   } else {
    //     this.renderOption = arr
    //   }
    // },
    // addFilterOptions (enterpriseName) {
    //   // 每次查找输入时，若有精确匹配的条目，保证该条目一定在renderOption内
    //   const target = this.options.find((item) => { // 从大option中找到当前条
    //     return item.enterpriseName === enterpriseName
    //   })
    //   if (target) { // 将当前条与小option比对，没有则加入
    //     if (this.renderOption.every(item => item.label !== target.label)) {
    //       this.renderOption.unshift(target)
    //     }
    //   }
    // },
    focus () {
      this.$nextTick(() => {
        if (this.$refs.baseSelect.$el.children[0].children[0]) {
          this.$refs.baseSelect.$el.children[0].children[0].setAttribute('maxlength', 100)
        }
      })
    },
    visibleChange (val) {
      if (val && !this.HideTips) {
        this.$nextTick(() => {
          this.getDomWidth()
        })
      } else {
        this.disabledArr = []
      }
    },
    // 获取内容宽度是否超出宽度
    getDomWidth () {
      this.init()
      const dom = this.$refs.valueBox
      dom && dom.forEach((item, index) => {
        this.$set(
          this.disabledArr,
          index,
          item.offsetWidth >= item.scrollWidth
        )
      })
    },
    selfChange (val) {
      const {
        options,
        keyId,
        $attrs: { multiple }
      } = this
      const ids = multiple ? val : [val]
      const curSelected = options.filter((it) => ids.indexOf(it[keyId]) > -1)
      let row
      if (multiple) {
        // 多选
        row = curSelected
      } else {
        row = curSelected.length ? curSelected[0] : {}
      }
      this.$emit('change', val, row, this.$attrs.keyIndex)
    },
    /**
     * 过滤重复的key属性
     */
    filterDouble (data = []) {
      const [key] = this.selectedField
      const res = []
      const empty = Object.create(null)
      data.forEach((it) => {
        const keyName = it[key]
        if (!empty[keyName]) {
          res.push(it)
          empty[keyName] = true
        }
      })
      return res
    }
  },
  computed: {

    currentListeners () {
      this.init()
      const _this = this
      return Object.assign(
        {},
        {
          ...this.$listeners,
          change: function (...arg) {
            _this.selfChange(...arg)
          }
        }
      )
    },
    currentOptions () {
      const options = this.filterDouble(this.options)
      if (typeof this.optionsFilter === 'function') {
        return this.optionsFilter(options)
      }
      return options
    },
    keyName () {
      return this.selectedField[1] || 'name'
    },
    keyId () {
      return this.selectedField[0] || 'id'
    },
    defaultFirstOption () {
      return this.allowCreate
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.item{
width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
