import { getBaseUserInfo, getDictList, getSysMenus } from '@/api/sys/user'
import { buildMenusData } from '@/utils/auth/menuHelp'

const state = {
  initFlag: false
}

const actions = {
  // 设置登录的基础数据信息
  initUserBaseInfo: {
    root: true,
    handler: async ({ commit }) => {
      try {
        commit('changeInitState', false)
        // 菜单信息
        const { data: menus = [] } = await getSysMenus()
        // 缓存原始数据
        commit('sys/setMenusList', menus, { root: true })
        // 设置权限数据
        commit('sys/setPerms', menus, { root: true })
        // 菜单使用的数据
        commit('sys/setMenus', buildMenusData(menus), { root: true })

        // 用户信息
        const { data: userInfo } = await getBaseUserInfo()
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        // const newUserInfo = JSON.parse(JSON.stringify(userInfo))
        commit('user/SET_BASE_USER_INFO', userInfo, { root: true })
        commit('user/SET_OPERATE_ID', userInfo.userId, { root: true })

        // 字典信息数据
        const { data } = await getDictList()
        commit('sys/setDictMaps', data, { root: true })
        commit('changeInitState', true)
        return menus
        // 加载完数据 状态变更
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  changeUserInfo: async ({ commit }) => {
    const { data: userInfo } = await getBaseUserInfo()

    commit('user/SET_BASE_USER_INFO', userInfo, { root: true })
    commit('user/SET_OPERATE_ID', userInfo.userId, { root: true })
  }
}

const mutations = {
  changeInitState: (state, status) => {
    state.initFlag = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
