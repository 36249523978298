const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 融资需求管理
  {
    path: '/financingDemandManagement/financingDemandManagement',
    name: 'financingDemandManagement',
    component: _import('financing-demand-management/financing-demand-management-list'),
    meta: {
      cache: false
    }
  }
]
