const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 账户管理
  {
    path: '/risk/accountManage',
    name: 'accountManage',
    component: _import('risk/account-manage/account-manage'),
    meta: {
      cache: false
    }
  },
  // 风险监控
  {
    path: '/risk/riskDetection',
    name: 'riskDetection',
    component: _import('risk/risk-detection/risk-detection'),
    meta: {
      cache: false
    }
  },
  // 合同加解锁
  {
    path: '/risk/contractLock',
    name: 'contractLock',
    component: _import('risk/contract-lock/contract-lock'),
    meta: {
      cache: false
    }
  },
  // 负面舆情
  {
    path: '/risk/negativePublicOpinion',
    name: 'negativePublicOpinion',
    component: _import('risk/negative-public-opinion/negative-public-opinion'),
    meta: {
      cache: false
    }
  },
  // 供应商加/解锁
  {
    path: '/risk/supplierLock',
    name: 'negativePublicOpinion',
    component: _import('risk/supplier-lock/supplier-lock'),
    meta: {
      cache: false
    }
  }
]
