
let lock = true
let el = null

const MousedownEvent = new Event('mousedown', { bubbles: true })

const MouseupEvent = new Event('mouseup', { bubbles: true })

const fakeClickOutSide = () => {
  document.dispatchEvent(MousedownEvent)

  document.dispatchEvent(MouseupEvent)

  lock = true // console.log('dispatchEvent');
}

const mousedownHandle = (e) => {
  const classList = e.target.classList

  if (
    classList.contains('el-select__caret') ||
    classList.contains('el-input__inner')
  ) {
    lock = false

    return
  }

  if (lock) return

  fakeClickOutSide()
}
// 滚动鼠标 关闭所有下拉显示框
const closeAllDropDown = () => {
  // const SELECTWRAP_BODY = document.body // body
  // Vue.$nextTick(() => {

  // })

  // if (e.target.__vue__) {
  //   const self = e.target.__vue__
  //   console.log(self.$children[1])
  //   if (self.$children[0].lenght > 0) {
  //     console.log(self.$children[1].$refs.baseSelect)
  //   }
  // }
  // Vue.$nextTick(() => {
  //   console.log(1)
  // })
  // console.log(resfSelect)
  // resfSelect.forEach((item) => {

  // })

  // const SELECTWRAP_DOWNALL = document.querySelectorAll('.el-select-dropdown') // select下拉框
  // // const SELECTWRAP_TIMEALL = document.querySelectorAll('.s-time-panel') // 时间下拉框
  // // const SELECTWRAP_DATEALL = document.querySelectorAll('.s-picker-panel') // 日期下拉框
  // SELECTWRAP_BODY.click()

  // SELECTWRAP_DOWNALL.forEach((item) => {
  //   item.style.display = 'none'
  // })

  // SELECTWRAP_TIMEALL.forEach((item) => {
  //   item.style.display = 'none'
  // })
  // SELECTWRAP_DATEALL.forEach((item) => {
  //   item.style.display = 'none'
  // })
}

const mousewheelHandle = (e) => {
  closeAllDropDown(e)
  if (
    lock ||
    e.target.classList.contains('el-select-dropdown__item') ||
    e.target.parentNode.classList.contains('el-select-dropdown__item') ||
    e.target.classList.contains('el-cascader-node__label') ||
    e.target.classList.contains('el-cascader-node')
  ) {
    return
  }

  fakeClickOutSide()
}
//  添加滚动事件
const eventListener = (type) => {
  el[type + 'EventListener']('mousedown', mousedownHandle)

  window[type + 'EventListener']('mousewheel', mousewheelHandle)

  window[type + 'EventListener']('DOMMouseScroll', mousewheelHandle) // fireFox 3.5+
}

export default {
  mounted () {
    el = this.$root.$el

    el.addFakeClickOutSideEventCount = el.addFakeClickOutSideEventCount || 0

    !el.addFakeClickOutSideEventCount &&
      this.$nextTick(() => {
        eventListener('add')
      })

    el.addFakeClickOutSideEventCount += 1
  },

  destroyed () {
    eventListener('remove')

    el.addFakeClickOutSideEventCount -= 1
  }
}
