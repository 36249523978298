<template>
  <div class="layout-aside" :class="{open: openFlag}">
    <div class="logo-wrapper">
      <div class="img_container" @click="goHome">
        <img :src="logoImage" alt="稳诚一方" />
      </div>
    </div>
    <menus></menus>
  </div>
</template>

<script>
import Menus from './components/menus.vue'
export default {
  computed: {
    openFlag () { return this.$store.state.sys.openFlag }
  },
  components: { Menus },
  data () {
    return {
      logoImage: require('@/assets/layout/logo_bg.png')
    }
  },
  methods: {
    goHome () {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style></style>
