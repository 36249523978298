// 需要添加menuId 的接口

export default [
  '/api/product/statistics/capitalSideInfo',
  '/api/finance/statistics/loanAmountProportion',
  '/api/finance/statistics/distributionCondition',
  '/api/finance/statistics/financingRisk',
  '/api/finance/statistics/financingProduct',
  '/api/finance/statistics/supplieRranking',
  'api/finance/manage/page',
  'api/finance/business/progress/page',
  '/api/finance/loanplan/page',
  'api/finance/repayment/plan/page',
  '/api/finance/repayregist/page',
  '/api/finance/risk/supervise/account',
  '/api/finance/risk/page',
  '/api/finance/finpaybook/page',
  '/api/finance/finpaybook/loanAmountSum',
  '/api/finance/finpaybook/export',
  '/api/finance/task/todo',
  'api/finance/business/progress/page',
  '/api/finance/business/progress/todo',
  'api/finance/sign/page',
  'api/finance/admittance/page',
  '/api/finance/admittance/approval/todo'
]
