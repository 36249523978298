import router from '@/router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { checkRouteInProject } from './router/router-check'
import store from './store/index'
import { getToken } from './utils/auth/auth-storage'
import Storage from '@/utils/storage'
import { getParams } from './utils/auth/permission'
import { redirctLogin } from './utils/devUtils'

// 白名单，白名单上的不检查权限
const whiteList = ['/login']
const authWhiteList = ['/', '/401', '/404']
NProgress.configure({ showSpinner: false }) // NProgress Configuration

/**
 * 权限判定
 */
router.beforeEach(async (to, from, next) => {
  document.title = '泉州交发集团'
  NProgress.start()
  // 统一登录跳转
  const urlParams = getParams(to.fullPath)
  if (urlParams && urlParams.token) {
    await store.dispatch('user/initUserInfo', urlParams)
  }
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next('/')
      NProgress.done()
    } else {
      if (!checkRouteInProject(to.path)) {
        next({
          path: '/404'
        })
        NProgress.done()
      } else {
        let menus = []
        if (store.state.init.initFlag) {
          menus = store.state.sys.menusList
        } else {
          try {
            menus = await store.dispatch('initUserBaseInfo')
          } catch (error) {
            // next('/login')
            redirctLogin()
            NProgress.done()
            return
          }
        }
        const menusPaths = menus.map(it => it.path.toLocaleLowerCase())
        // 菜单权限判定
        if (!menusPaths.includes(to.path.toLocaleLowerCase()) && !authWhiteList.includes(to.path)) {
          console.log('401的路由', to.path)
          // next()
          next({
            path: '/401'
          })
        } else {
          // 处理预授信申请管理子页面刷新页面跳转问题
          if (to.path === '/business/applyForRufs' && !Storage.getSession('sublevel')) {
            Storage.setSession('sublevel', true)
            next()
          } else if (to.path === '/business/applyForRufs' && Storage.getSession('sublevel')) {
            Storage.setSession('sublevel', false)
            next({
              path: '/business/preCreditApplicationManage'
            })
          } else {
            Storage.setSession('sublevel', false)
            next()
          }
        }
        if (window._axiosPromiseArr) {
          window._axiosPromiseArr.forEach((ele, index) => {
            ele.cancel() // 路由跳转之前，清空（终止）上一个页面正在请求的内容

            // 清空请求的参数 清空请求的参数

            delete window._axiosPromiseArr[index]
          })
        }
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      if (to.path === '/login') {
        return redirctLogin()
      }
      next()
    } else {
      // 是否需要加这个~
      // next(`/login?redirect=${to.path}`)
      // next('/login')
      redirctLogin()
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
