<script>
import { formatRoutePath } from '@/router/router-check'
import { mapState } from 'vuex'

const buildIcon = name => {
  if (!name) {
    return 'iconfont iconshouye'
  } else if (!name.includes('iconfont')) {
    return `iconfont ${name}`
  } else {
    return name
  }
  // return 'iconfont iconshouye'
}

const normalizeMenus = menus => {
  return menus.map(it => {
    const { icon, children, ...rest } = it
    if (children && children.length) {
      return {
        ...rest,
        children: normalizeMenus(children),
        icon: buildIcon(icon)
      }
    } else {
      return {
        ...rest,
        icon: buildIcon(icon)
      }
    }
  })
}

export const IndexHelp = (item, menus) => {
  // const currentHidden = menus.find(it => it.path === item.path)?.hidden
  // console.log('currentHidden', currentHidden)
  // return item.path === '/' ? '/dashboard' : item.path
  return item.path
}

export default {
  data () {
    return {
      defaultIndex: ''
    }
  },
  computed: {
    ...mapState({
      isCollapse: state => state.sys.openFlag,
      menus: state => normalizeMenus(state.sys.menus),
      menusList: state => state.sys.menusList.map(it => ({ ...it, path: formatRoutePath(it.path) }))
    })
  },
  methods: {
    changeCurrentIndex (newRoute, oldRoute) {
      // 是否是隐藏的菜单项
      const { path } = newRoute

      const curPath = path.toLocaleLowerCase()
      const currentRouteHidden = this.menusList.find(it => it.path === curPath || it.name === name)?.hidden
      // 不是隐藏 不做处理
      if (!currentRouteHidden) {
        this.defaultIndex = path
      } else {
        if (oldRoute) {
          this.defaultIndex = oldRoute.path
        // const oldPathItem = this.menusList.find(it => it.path === oldPath)
        } else {
        // 跳转到首页
          this.$router.replace('/')
        }
      }
    }
  },
  watch: {
    $route: {
      handler (...arg) {
        this.changeCurrentIndex(...arg)
      },
      immediate: true
    }
  },
  render (h) {
    const { menus, isCollapse, menusList } = this
    const renderNormalItem = item => {
      if (item.hidden) {
        return null
      }
      return h(
        'el-menu-item',
        {
          attrs: {
            index: IndexHelp(item, menusList),
            key: item.id
          },
          scopedSlots: {
            title: () => <span>{item.title}</span>
          },
          on: {
            click (data) {
              sessionStorage.removeItem('activeNames')
            }
          }
        },
        // item.path === '/dashboard' ? [<i class={item.icon}></i>] : [<div class='menuitem'></div>]
        (item.path === '/' || item.path === '/dashboard') ? [<i class={item.icon}></i>] : [<div class='menuitem'></div>]
      )
    }
    const renderSlot = item => {
      return h(
        'template',
        {
          slot: 'title'
        },
        [
          <i class={item.icon}></i>,
          h('span', {
            attrs: {
              slot: 'title'
            }
          }, item.title)
        ]
      )
    }
    const renderChild = item => {
      const { children } = item
      return h(
        'el-submenu',
        {
          attrs: {
            index: item.path
          }
        },
        [renderSlot(item), ...renderMenuItems(children)]
      )
    }

    const renderMenuItems = menus => {
      return menus.map(it => {
        const { children } = it
        // 判断子菜单是否全部隐藏
        const isHidden = children && children.every(it => it.hidden === true)
        if (!children || isHidden) {
          return renderNormalItem(it)
        } else {
          return renderChild(it)
        }
      })
    }

    return h(
      'el-menu',
      {
        ref: 'menus',
        attrs: {
          mode: 'vertical',
          collapse: isCollapse,
          router: true,
          defaultActive: this.defaultIndex
        },
        class: {
          sz_aside: true
        }
      },
      renderMenuItems(menus)
    )
  }
}
</script>

<style></style>
