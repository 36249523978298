const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 统一文件预览
  {
    path: '/preView/preViewMain',
    name: 'preViewMain',
    component: _import('pre-view/pre-view'),
    meta: {
      cache: false
    }
  }
]
