import { httpHelp } from '@/httpHelp'
import axios from 'axios'
import qs from 'qs'
import {
  httpErrorHandle,
  requestInterceptor,
  responseInterceptor
} from './helper/http-util'

/**
 * axios 请求参数配置
 */
const requestConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 600000,
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false })
  }
}

const service = axios.create(requestConfig)
// 请求拦截处理
service.interceptors.request.use(
  (config) => requestInterceptor(config),
  (error) => Promise.reject(error)
)

// 响应处理
service.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => httpErrorHandle(error)
)

httpHelp(service)
export const http = service
export default service
