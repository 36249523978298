import router from '@/router/index'
import store from '@/store/index'
import { cancelAllPending, delPendingRequest } from './http-cancel'
import { hideLoading } from './http-loading'
import { errorMessage } from './http-util'

const TOKEN_OVERDUE_CODE = '160300004'

/**
 * token 过期退出登录
 */
export const loginOutByToken = () => {
  // 清除用户信息
  store.dispatch('user/clearUserInfo')

  // 信息提示
  errorMessage('用户信息过期,请重新登录')

  // 取消其它正在进行的请求
  cancelAllPending()
  // 跳转
  setTimeout(() => {
    router.replace({
      path: '/login'
    })
  }, 1000)
}

/**
 * 请求错误信息时的处理逻辑
 */
export const httpErrorCodeActions = (response) => {
  // 用户信息过期
  if (response.code === TOKEN_OVERDUE_CODE) {
    loginOutByToken()
  } else {
    errorMessage(response.message)
  }
}

export default (response) => {
  // 清楚pending拦截
  delPendingRequest(response.config)
  hideLoading(response.config)
  // 文件直接返回
  if (
    response.headers !== undefined &&
    response.headers !== null &&
    response.request.responseType === 'blob'
  ) {
    return response
  }
  // 处理返回数据结构
  let responseData = response.data
  let newData = JSON.stringify(response.data)
  const reg = new RegExp('-9999999999|"-9999999999.00"|-99900|-999|"-999"|-2209017600000', 'g')
  const regs = new RegExp('"".00', 'g')
  newData = newData.replace(reg, '""').replace(regs, '-999.00')

  responseData = JSON.parse(newData)
  responseData.success = responseData.code === '0' || responseData.code === 0
  // 错误信息处理
  if (!responseData.success) {
    httpErrorCodeActions(responseData)
    return Promise.reject(responseData)
  }

  return responseData
}
