  // 用户协议弹出框
<template>
     <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      :title="fileTitle"
      width="600px"
      class="agreementDialogHeight"
    >
    <div>
      <agreement></agreement>
    </div>
    <template slot="footer" >
     <base-button label="关闭" @click="visibleState = false" type="default"/>
    </template>
    </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import Agreement from './agreement.vue'
export default {
  props: {
    visible: Boolean,
    fileTitle: String
  },
  data () {
    return {
    }
  },
  components: { BaseDialog, BaseButton, Agreement },
  computed: {

    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {

      }
    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
.agreementDialogHeight {
  .el-dialog{
    max-height: 800px;
  }
}

</style>
