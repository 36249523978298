<template>
  <div>
    <base-dialog
      :visible.sync="visibleDialog"
      :custom-class="'authDialog'"
      width="600px"
      :showSubmit="true"
      :submitText="'同意'"
      @submit="submit"
      title="提示">
      <div class="content">
        <i class="el-icon-warning"></i>
        <div class="bigColor">为了融资业务正常开展，</div>
        <!-- <p>请您阅读并同意<span class="lookFile" @click="lookFile(DATA_AUTH_ORG_LIST[item])" v-for="(item, index) in capitalSideCodeList" :key="index">《{{DATA_AUTH_ORG_LIST[item]}}》</span>内容。</p> -->
        <p>请您阅读并同意<span class="lookFile" @click="lookFile('数据查询授权书')">《数据查询授权书》</span>内容。</p>
      </div>
    </base-dialog>
    <agreement-dialog :visible.sync="agreementVisible" :fileTitle="fileTitle"></agreement-dialog>
  </div>
</template>
<script>
import Storage from '@/utils/storage'
import { updateSupplierAuth } from '@/api/sys/user'

import AgreementDialog from './components/agreement-dialog.vue'
import BaseDialog from '../common/base-dialog/base-dialog.vue'
import { DATA_AUTH_ORG_LIST } from '@/config'
export default {
  props: {
    visible: Boolean
  },
  data () {
    return {
      agreementVisible: false,
      DATA_AUTH_ORG_LIST: DATA_AUTH_ORG_LIST,
      fileTitle: ''
    }
  },
  computed: {
    visibleDialog: {
      get () {
        return this.visible
      },
      set (val) {
        if (!val) {
          Storage.setSession('authStatus', false)
        }
        this.$emit('update:visible', val)
      }
    },
    orgId () {
      return this.$store.state.user.userInfo.organizationId
    },
    // 资金方id列表
    capitalSideCodeList () {
      return Storage.getSession('capitalSideCode')
    }
  },
  components: { BaseDialog, AgreementDialog },
  created () {
    this.showHint()
  },
  methods: {
    showHint () {
      if (this.orgId) {
        this.visible = true
      }
    },
    lookFile (title) {
      this.agreementVisible = true
      this.fileTitle = title
    },
    async submit () {
      const res = await updateSupplierAuth({ gysId: this.orgId, authState: '1', capitalSideCodeList: this.capitalSideCodeList })
      if (res.data && res.data.code === '0') {
        this.success('授权成功')
      } else if (res.data && res.data.code === '1') {
        this.error(res.data.message)
      }
      this.visibleDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.bigColor{

}
/deep/.authDialog {

  height: 300px !important;
  .content {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    color: rgb(55, 55, 58);
    .el-icon-warning {
      font-size: 60px;
      color: rgb(255, 131, 77)
    }
    .lookFile {
     color: rgb(255, 131, 77);
      cursor: pointer;
    }
  }
}
</style>
