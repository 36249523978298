import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import sys from './modules/sys'
import init from './modules/init'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  getters,
  modules: {
    user,
    init,
    sys
  }
})
