<template>
  <!-- 自定义确认弹窗样式 -->
  <BaseDialog
    :title="content.title"
    :visible.sync="content.show"
    v-if="isShow"
    class="stateConfirm"
    :showFooter="false"
    :before-close="close"
  >
    <div>
      <div class="icon" v-if="content.type">
        <i
          class="iconfont iconchenggong1"
          v-if="content.type === 'success'"
        ></i>
        <i class="iconfont icontanhao" v-if="content.type === 'warning'"></i>
        <i class="iconfont iconshibai1" v-if="content.type === 'error'"></i>
      </div>
       <div class="message"  v-if="content.messageType==='Array'">
         以下文件未生成成功：<span  v-for="(item,index) of content.message" :key="index">
           {{item.label}} <span v-if="index!==content.message.length-1">,</span>
         </span>
        <div v-for="(item,index) of content.message" :key="index">
          <span>{{item.label}}：</span><span>{{item.message}}</span>
        </div>
      </div>
      <div class="message" v-else-if="content.messageType==='ArrayMessage'">
        <span  v-for="(item,index) of content.message" :key="index">
           {{item}} <span v-if="index!==content.message.length-1">,</span>
         </span>
      </div>
      <div class="message" v-else>{{ content.message }}</div>

    </div>
    <div slot="footer" class="dialog-footer">
      <BaseButton type="primary" v-if="content.showConfirmButton === false ? false: true" @click="ok" :label="content.confirmButtonText||'确定'"> </BaseButton>
      <BaseButton @click="close" v-if="content.showCancelButton === false ? false: true" :label="content.cancelButtonText||'取消'" type='default'> </BaseButton>
    </div>
  </BaseDialog>
</template>

<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
export default {
  name: 'stateConfirm',
  components: { BaseDialog, BaseButton },
  data () {
    return {
      // 弹窗内容
      isShow: true,
      content: {
        title: '',
        message: '',
        data: '',
        show: false,
        type: '',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '',
        cancelButtonText: ''
      }
    }
  },
  methods: {
    close () {},
    ok () {}
  }
}
</script>

<style lang='scss' src="./state-confirm.scss" scope></style>
