<template>
  <div class="layout-main">
    <lay-aside></lay-aside>
    <div class="lay-wrapper" :class="{ open: openFlag }">
      <lay-header></lay-header>
      <lay-tags v-if="!screenLoading"></lay-tags>
      <div
        class="app-main"
        v-loading="screenLoading"
        element-loading-text="系统数据配置中..."
      >
        <transition name="fade-transform" mode="out-in">
          <keep-alive :include="keepInclude">
            <router-view v-if="!screenLoading && freshFlag" />
          </keep-alive>
        </transition>
      </div>
    </div>
    <supplier-auth :visible.sync="visible" v-if="visible"></supplier-auth>
  </div>
</template>

<script>
import LayAside from './lay-aside.vue'
import layHeader from './lay-header.vue'
import LayTags from './lay-tags.vue'
import SupplierAuth from '@/components/supplier-auth/supplier-auth.vue'
import { checkAuthorization, getCapitalSideCode } from '@/api/sys/user'
import Storage from '@/utils/storage'
export default {
  components: { layHeader, LayAside, LayTags, SupplierAuth },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    freshFlag () {
      return this.$store.state.sys.freshFlag
    },
    openFlag () {
      return this.$store.state.sys.openFlag
    },
    keepInclude () {
      return [...this.$store.state.sys.tagsCache]
    },
    screenLoading () {
      return !this.$store.state.init.initFlag
    },
    organizationId () {
      return this.$store.state.user.userInfo.organizationId
    },
    authStatus () {
      return Storage.getSession('authStatus')
    }
  },
  mounted () {
    if (this.authStatus) {
      this.getAuth()
    }
  },
  methods: {
    async getAuth () {
      if (this.organizationId) {
        const res = await checkAuthorization({ gysId: this.organizationId })
        if (res && res.data) {
          const capitalSideCode = await getCapitalSideCode({ gysId: this.organizationId })
          Storage.setSession('capitalSideCode', capitalSideCode.data)
          this.visible = true
        } else {
          Storage.setSession('authStatus', false)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-10px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(10px);
}
</style>
