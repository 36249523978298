const projectRoute = []

// 格式化路由参数
export const formatRoutePath = (path) => {
  // 先转小写
  path = path.toLocaleLowerCase()
  if (path[0] !== '/') {
    path = `/${path}`
  }
  return path
}
export const checkRoutesConfig = (routes = []) => {
  // 开发模式推出
  const undefinedMap = []
  const buildMaps = (data = []) => {
    data.forEach(route => {
      projectRoute.push(formatRoutePath(route.path))
      const { name, children = [] } = route
      if (children.length > 0) {
        buildMaps(children)
      } else {
        if (!name) {
          undefinedMap.push(route)
        }
      }
    })
  }
  buildMaps(routes)

  if (undefinedMap.length && process.env === 'development') {
    console.table('没有配置name属性的菜单项:', undefinedMap)
  }
}
/**
 * 校验路由是否是项目中的
 */
export const checkRouteInProject = (path) => {
  return projectRoute.includes(path.toLocaleLowerCase())
}
