const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  {
    path: '/basicDataManagement/projectCompanyManagement',
    name: 'projectCompanyManagement',
    component: _import('basic-data-management/project-company-management/index'), // 项目公司管理
    meta: {
      cache: false
    }
  },
  {
    path: '/addProjectCompany',
    name: 'addProjectCompany',
    component: _import('basic-data-management/project-company-management/addSupplier') // 新增项目公司
  },
  {
    path: '/basicDataManagement/supplierManagement',
    name: 'supplierManagement',
    component: _import('basic-data-management/supplier-management/index'), // 供应商管理
    meta: {
      cache: false
    }
  },
  {
    path: '/addSupplier',
    name: 'addSupplier',
    component: _import('basic-data-management/supplier-management/addSupplier') // 新增供应商
  },
  { // 角色管理
    path: '/basicDataManagement/rolesManagment',
    name: 'rolesManagement',
    component: _import('basic-data-management/role-management/role-management-list')
  },
  { // 角色授权
    path: '/basicDataManagement/rolesManagment/jurisdiction',
    name: 'rolesManagmentJurisdiction',
    component: _import('basic-data-management/role-management/role-management-auth-list')
  },
  { // 用户管理
    path: '/basicDataManagement/userManagment',
    name: 'userManagement',
    component: _import('basic-data-management/user-management/user-management-list')
  }, { // 组织机构管理
    path: '/basicDataManagement/orgMain',
    name: 'orgMain',
    component: _import('basic-data-management/organization-management/organization-management-list')
  },
  { // 证书管理
    path: '/basicDataManagement/certificateManagement',
    name: 'certificateManagement',
    component: _import('basic-data-management/certificate-management/certificate-management')
  },
  { // 证书管理>新增&修改企业认证
    path: '/basicDataManagement/enterpriseCertification',
    name: 'enterpriseCertification',
    component: _import('basic-data-management/certificate-management/enterprise-certification')
  },
  { // 证书管理>新增&修改个人认证
    path: '/basicDataManagement/personalCertification',
    name: 'personalCertification',
    component: _import('basic-data-management/certificate-management/personal-certification')
  }

]
