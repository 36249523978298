import { getAppLists } from '@/api/sys/user'
import { APP_CODE } from '@/config/constant'
import { getSysId, getTenant, getToken, removeToken, setSysId, setTenant, setToken } from '@/utils/auth/auth-storage'

export default {
  namespaced: true,
  state: {
    token: getToken() || '',
    tenantId: getTenant() || '',
    clientId: getSysId() || '',
    operateId: '',
    tokenInfo: {},
    userInfo: {}
  },
  actions: {
    /**
     * 设置用户基本信息
     */
    initUserInfo: async ({ commit }, userInfo) => {
      try {
        const { token, tenantId } = userInfo
        const headers = {
          Authorization: 'Bearer ' + token,
          token: token,
          tenantId: tenantId
        }
        const data = await getAppLists({}, headers)
        console.log(data, 111)
        const { data: [{ appLists }] } = await getAppLists({}, headers)
        const currentApp = appLists.find(it => it.appCode === APP_CODE)
        if (!currentApp || !currentApp.appId) {
          console.error('没有获取到appId')
          return Promise.reject(new Error('没有获取到应用权限'))
        }
        commit('SET_TOKEN', token)
        commit('SET_TENANT_ID', tenantId)
        commit('SET_SYS_ID', currentApp.appId)
      } catch (error) {
        Promise.reject(new Error(`用户信息初始化失败：[${error}]`))
      }
    },
    // 清楚登录用户所有信息
    clearUserInfo: ({ commit }) => {
      commit('SET_TOKEN_INFO', {})
      commit('SET_TOKEN', '')
      commit('SET_TENANT_ID', '')
      commit('SET_SYS_ID', '')
      commit('SET_BASE_USER_INFO', {})
      removeToken()

      commit('init/changeInitState', false, { root: true })
    }
  },
  mutations: {
    SET_TOKEN_INFO: (state, info) => {
      state.tokenInfo = info
    },
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
    },
    SET_OPERATE_ID: (state, userId) => {
      state.operateId = userId
    },
    SET_TENANT_ID: (state, tenantId) => {
      setTenant(tenantId)
      state.tenantId = tenantId
    },
    SET_SYS_ID: (state, id) => {
      setSysId(id)
      state.clientId = id
    },
    SET_BASE_USER_INFO: (state, userInfo) => {
      // console.log('userInfo', userInfo)
      state.userInfo = userInfo
    }
  }
}
