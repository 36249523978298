<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import fackClickOutSide from '@/plugin/fackClickOutSide'
export default {
  mixins: [fackClickOutSide]
}
</script>
