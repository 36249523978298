/**
 * 开发设置对应后端开发的处理
 * @param {*} axios 实例
 */
export const httpHelp = (http) => {
  if (process.env.NODE_ENV === 'development') {
    http.interceptors.request.use(config => {
      // if (config.url.includes('finance') || config.url.includes('finance/business/progress')) {
      //   config.baseURL = 'http://192.168.138.30:38919'
      //   return config
      // }
      // if (config.url.includes('asset/')) {
      //   config.baseURL = 'http://192.168.136.43:38911'
      //   return config
      // }
      // if (config.url.includes('api/company')) {
      //   config.baseURL = 'http://192.168.136.18:38913'
      //   return config
      // }
      // if (config.url.includes('file')) {
      //   config.baseURL = 'http://192.168.138.176:38913'
      //   return config
      // }
      // if (config.url.includes('file')) {
      //   config.baseURL = 'http://192.168.138.176:38913'
      //   return config
      // }
      // if (config.url.includes('finance')) {
      //   config.baseURL = 'http://192.168.136.44:38910'
      //   return config
      // }
      // if (config.url.includes('api/finance')) {
      //   config.baseURL = 'http://192.168.136.43:38910'
      //   return config
      // }
      // if (config.url.includes('api/company')) {
      //   config.baseURL = 'http://192.168.136.43:38913'
      //   return config
      // }
      // if (config.url.includes('api/authcenter')) {
      //   config.baseURL = 'http://192.168.136.43:28002/'
      //   return config
      // }
      // if (config.url.includes('api/product')) {
      //   config.baseURL = 'http://192.168.136.43:38912'
      //   return config
      // }
      // if (config.url.includes('api/asset')) {
      //   config.baseURL = 'http://192.168.136.43:38911'
      //   return config
      // }
      // if (config.url.includes('finance/duediligence')) {
      //   config.baseURL = 'http://192.168.138.30:38910'
      //   return config
      // }
      // if (config.url.includes('finance/reviewmeeting')) {
      //   config.baseURL = 'http://192.168.138.93:38910'
      //   return config
      // }
      // if (config.url.includes('finance')) {
      //   config.baseURL = 'http://192.168.138.17:38910'
      //   return config
      // }
      // if (config.url.includes('company')) {
      //   config.baseURL = 'http://192.168.138.104:38913'
      //   return config
      // }
      // if (config.url.includes('/api/finance/task/node/list') && !config.url.includes('/api/finance/filelist/detail/file/upload')) {
      //   config.baseURL = 'http://192.168.136.29:38910'
      //   return config
      // }
      // if (config.url.includes('supplier')) {
      //   config.baseURL = 'http://192.168.138.30:38913'
      //   return config
      // }
      return config
    })
  }
}
