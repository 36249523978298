import http from '@/utils/http/index'

/**
 * 获取系统菜单
 */
export function getSysMenus () {
  return http({
    hideLoading: true,
    method: 'get',
    url: 'api/authcenter/authority/menu/list',
    params: { loadOp: 1 }
  })
}
/**
 * 登录接口
 * @param {*} data
 * @returns
 */
export const login = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: '/api/authcenter/login',
    data
  })
}
/**
 * 校验是否需要弹窗
 * @param {*} data
 * @returns
 */
export const checkAuthorization = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: `/api/product/supplier/checkAuthorization?gysId=${data.gysId}`,
    data
  })
}
/**
/**
 * 修改供应商状态
 * @param {*} data
 * @returns
 */
export const updateSupplierAuth = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: '/api/product/supplier/updateSupplierAuth',
    data
  })
}
/**
/**
 * 获取资金方编码
 * @param {*} data
 * @returns
 */
export const getCapitalSideCode = (params) => {
  return http({
    showLoading: true,
    method: 'get',
    url: '/api/product/supplier/getCapitalSideCode',
    params
  })
}
/**
 * 获取用户的app列表
 * @param {*} params
 * @param {*} headers
 * @returns
 */
export const getAppLists = (params, headers) => {
  return http({
    method: 'get',
    url: '/api/authcenter/base/tenant/user/curuser/tenants',
    params,
    headers
  })
}
/**
 * 字典接口
 * @returns
 */
export const getDictList = () => {
  return http({
    hideLoading: true,
    method: 'get',
    url: '/api/dict/dict/list'
  })
}
/**
 * 基础用户数据
 * @returns
 */
export const getBaseUserInfo = () => {
  return http({
    hideLoading: true,
    method: 'get',
    url: '/api/authcenter/base/user'
  })
}

/**
 * 用户管理- 用户列表
 * @returns
 */
export const getUserList = (params) => {
  return http({
    method: 'get',
    url: '/api/authcenter/base/user/page',
    params
  })
}
/**
 *
 * @param {*} params
 * @returns
 */
export const getUserListOrgName = async (params) => {
  try {
    // 用户列表数据
    const res = await getUserList(params)
    const preData = res.data.datas
    const ids = preData.map(it => it.organizationId).filter(it => !!it)
    if (!ids.length) {
      return res
    }
    const { data: configs } = await getUserOrgNames({ keyIds: ids })
    // 转map
    const orgMap = {}
    configs.forEach(it => {
      const { keyId, enterpriseName } = it
      orgMap[keyId] = enterpriseName
    })
    res.data.datas = preData.map(it => {
      return {
        ...it,
        orgName: orgMap[it.organizationId]
      }
    })
    return res
  } catch (error) {
    return error
  }
}

/**
 * 用户获取机构名称
 * @param {*} params
 * @returns
 */
export const getUserOrgNames = (params) => {
  return http({
    method: 'get',
    url: '/api/company/supplier/listcompany',
    params
  })
}
/**
 * 用户管理- 新增用户
 */
export const createUser = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: '/api/authcenter/base/user',
    data
  })
}

/**
 * 用户管理- 新增用户业务服务
 */
export const createUserByBiz = (data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: '/api/company/personnel',
    data
  })
}

/**
 * 用户管理- 新增用户
 */
export const updateUser = (keyId, data) => {
  return http({
    showLoading: true,
    method: 'put',
    url: `/api/authcenter/base/user/${keyId}`,
    data
  })
}

/**
 * 用户管理-修改用户
 */
export const updateUserByBiz = (keyId, data) => {
  return http({
    showLoading: true,
    method: 'put',
    url: `/api/company/personnel/${keyId}`,
    data
  })
}
/**
 * 用户管理- 重置密码
 */
export const resetPwd = (data) => {
  return http({
    method: 'POST',
    url: 'api/authcenter/base/user/pwd/post',
    data
  })
}
/**
 * 用户管理- 重置密码
 */
export const resetPwdById = (data) => {
  return http({
    method: 'put',
    url: `api/authcenter/base/user/pwd/${data.userId}`,
    data
  })
}
/**
 * 权限相关 -输入的用户判定
 */
export const checkInputExit = (params) => {
  return http({
    hideLoading: true,
    url: 'api/authcenter/base/user/exist',
    method: 'get',
    params
  })
}
/**
 * 权限相关 -输入的用户判定
 */
export const checkInputExitPhone = (params) => {
  return http({
    hideLoading: true,
    url: '/api/authcenter/base/user/getByPhone',
    method: 'get',
    params
  })
}
/**
 * OA 输入重复校验
 * @param {*} params
 * @returns
 */
export const checkInputExitOa = (params) => {
  return http({
    hideLoading: true,
    url: 'api/authcenter/base/user/exist/oa',
    method: 'get',
    params
  })
}

export const saveUserRoles = (isAuthData, data) => {
  return http({
    showLoading: true,
    method: 'post',
    url: `/api/authcenter/base/user/role?isAuthData=${isAuthData}`,
    data
  })
}

export const getUserRoles = (keyId, params) => {
  return http({
    method: 'get',
    url: `/api/authcenter/base/user/roles/wsf/${keyId}`,
    params
  })
}
export const getRoles = (keyId) => {
  return http({
    method: 'get',
    url: `/api/authcenter/base/user/roles/${keyId}`,
    keyId
  })
}
/**
 *获取经理类型
 */
export const getManager = (params) => {
  return http({
    method: 'get',
    url: '/api/authcenter/base/role/user/list',
    params
  })
}
/*
 * 根据所属机构获取机构名称下拉配置项
 * @param {*} params
 * @returns
 */
export const getOrgConfigsByOrgType = (params) => {
  return http({
    method: 'get',
    url: '/api/company/supplier/listbylabel',
    params
  })
}

// 下载用户导入模板
export const downloadUserTemplate = (params) => {
  return http({
    method: 'post',
    url: 'api/file/filegen/download/code',
    params,
    responseType: 'blob'
  })
}
