const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 业务审核总页面 》 审核页面
  {
    path: '/business/newDetails',
    name: 'newDetails',
    component: _import('business/financing-business-manage/components/new-detail'),
    meta: {
      cache: false
    }
  },
  {
    path: '/business/financingBusinessManage',
    name: 'financingBusinessManage',
    component: _import('business/financing-business-manage/financing-business-manage'),
    meta: {
      cache: false
    }
  },
  // 业务审核总页面
  {
    path: '/business/examine',
    name: 'examine',
    component: _import('business/examine/examine-main'),
    meta: {
      cache: false
    }
  },
  // 业务审核总页面 》 审核页面
  {
    path: '/business/auditTypeList',
    name: 'auditTypeList',
    component: _import('business/examine/audit-type-list'),
    meta: {
      cache: false
    }
  },
  // 尽调报告管理列表
  {
    path: '/business/reportProductionMake',
    name: 'reportProductionMake',
    component: _import('business/report-production-make/report-production-make-list'),
    meta: {
      cache: false
    }
  },
  // 尽调报告管理列表
  {
    path: '/business/reportProductionInfo',
    name: 'reportProductionInfo',
    component: _import('business/report-production-info/make-process-info'),
    meta: {
      cache: false
    }
  },
  // 尽调报告制作流程
  {
    path: '/business/report-production-make/makeProcess',
    name: 'makeProcess',
    component: _import('business/report-production-make/make-process')
    // meta: {
    //   cache: false
    // }
  },
  // 尽调报告审核列表
  {
    path: '/business/reportProductionExamine',
    name: 'reportProductionExamine',
    component: _import('business/report-production/report-production-examine/report-production-examine-tabs'),
    meta: {
      cache: false
    }
  },
  // 尽调报告审核
  {
    path: '/business/reportProductionExamineInfo',
    name: 'reportProductionExamineInfo',
    component: _import('business/report-production/report-production-examine/report-production-examine'),
    meta: {
      cache: false
    }
  },

  // 评审会批复管理列表
  {
    path: '/business/approvalReviewMeetingMake',
    name: 'approvalReviewMeetingMake',
    component: _import('business/approval-review-meeting/approval-review-meeting-make/approval-review-meeting-make-list'),
    meta: {
      cache: false
    }
  },
  // 评审会落实管理列表
  {
    path: '/business/approvalReviewMeetingImplementList',
    name: 'approvalReviewMeetingImplementList',
    component: _import('business/approval-review-meeting/approval-review-meeting-make/approval-review-meeting-implement-list'),
    meta: {
      cache: false
    }
  },
  // 评审会批复管理制作
  {
    path: '/business/approvalProduction',
    name: 'approvalProduction',
    component: _import('business/approval-review-meeting/approval-review-meeting-make/approval-review-meeting-make'),
    meta: {
      cache: false
    }
  },
  // 评审会详情查看
  {
    path: '/business/approvalReviewMeetingInfo',
    name: 'approvalReviewMeetingInfo',
    component: _import('business/approval-review-meeting/approval-review-meeting-make/approval-review-meeting-info'),
    meta: {
      cache: false
    }
  },
  // 会议信息管理
  {
    path: '/business/meetingInfoManage',
    name: 'meetingInfoManage',
    component: _import('business/meeting-info-manage/meeting-info-manage'),
    meta: {
      cache: false
    }
  },
  // 评审会批复管审核列表
  {
    path: '/business/approvalReviewMeetingExamine',
    name: 'approvalReviewMeetingExamine',
    component: _import('business/approval-review-meeting/approval-review-meeting-examine/approval-review-meeting-examine-tabs'),
    meta: {
      cache: false
    }
  },
  // 评审会批复管审核
  {
    path: '/business/approvalReviewMeetingExamineInfo',
    name: 'approvalReviewMeetingExamineInfo',
    component: _import('business/approval-review-meeting/approval-review-meeting-examine/approval-review-meeting-examine'),
    meta: {
      cache: false
    }
  },

  // 融资申请管理列表
  {
    path: '/business/financingApplicationManagement',
    name: 'financingApplicationManagement',
    component: _import('business/financing-application-management/financing-application-management-list'),
    meta: {
      cache: false
    }
  },
  // 融资申请制作
  {
    path: '/business/accessDocumentsMake',
    name: 'accessDocumentsMake',
    component: _import('business/financing-application-management/access-documents-make'),
    meta: {
      cache: false
    }
  },
  // 融资申请审核列表
  {
    path: '/business/financingApplicationExamine',
    name: 'financingApplicationExamine',
    component: _import('business/financing-application-examine/financing-application-examine-tabs'),
    meta: {
      cache: false
    }
  },
  // 融资申请
  {
    path: '/business/financingApplicationExamineInfo',
    name: 'financingApplicationExamineInfo',
    component: _import('business/financing-application-examine/financing-application-examine'),
    meta: {
      cache: false
    }
  },
  // 融资决议管理
  {
    path: '/business/financingDecisionManage',
    name: 'financingDecisionManage',
    component: _import('business/financing-decision-manage/financing-decision-manage-list'),
    meta: {
      cache: false
    }
  },
  // 融资决议制作
  {
    path: '/business/decisionMake',
    name: 'decisionMake',
    component: _import('business/financing-decision-manage/decision-make'),
    meta: {
      cache: false
    }
  },
  // 融资决议审核
  {
    path: '/business/resolutionReview',
    name: 'resolutionReview',
    component: _import('business/resolution-review/resolution-review'),
    meta: {
      cache: false
    }
  },
  // 融资决议审核
  {
    path: 'business/resolutionReview/audit',
    name: 'audit',
    component: _import('business/resolution-review/resolution-review-audit'),
    meta: {
      cache: false
    }
  },
  // 放款登记
  {
    path: '/business/lendingRegistration',
    name: 'lendingRegistration',
    component: _import('business/lending-registration/lending-registration'),
    meta: {
      cache: false
    }
  },
  // 合同文件管理
  {
    path: '/business/contractFileManage',
    name: 'contractFileManage',
    component: _import('business/contract-file-manage/contract-file-manage-list'),
    meta: {
      cache: false
    }
  },
  // 合同文件管理详情
  {
    path: '/business/contractFileManage/info',
    name: 'contractFileManageInfo',
    component: _import('business/contract-file-manage/contract-file-manage-info'),
    meta: {
      cache: false
    }
  },
  // 合同文件审核
  {
    path: '/business/contractFileReview',
    name: 'contractFileReview',
    component: _import('business/contract-file-review/contract-file-review-tabs'),
    meta: {
      cache: false
    }
  },
  {
    path: '/business/repaymentplan',
    name: 'repaymentplan',
    meta: {
      cache: false
    },
    component: _import('business/repayment-plan/index') // 还款计划管理
  },
  {
    path: '/repaymentPlanInfo',
    name: 'repaymentPlanInfo',
    meta: {
      cache: false
    },
    component: _import('business/repayment-plan/info') // 还款计划详情
  },
  {
    path: '/business/essentialFactor/manage',
    name: 'manage',
    meta: {
      cache: false
    },
    component: _import('business/essential-factor/essential-factor-mange/essential-factor-mange-list') // 要素落实管理
  },
  {
    path: '/business/essentialFactor/elementProduction',
    name: 'elementProduction',
    meta: {
      cache: false
    },
    component: _import('business/essential-factor/essential-factor-mange/essential-factor-mange-make') // 要素落实制作
  },
  {
    path: '/business/essentialFactor/implementationReview',
    name: 'implementationReview',
    meta: {
      cache: false
    },
    component: _import('business/essential-factor/essential-factor-examine/essential-factor-examine-tabs') // 要素落实审核
  },
  {
    path: '/business/essentialFactor/ImplementAudit',
    name: 'ImplementAudit',
    meta: {
      cache: false
    },
    component: _import('business/essential-factor/essential-factor-examine/essential-factor-examine-audit') // 要素落实审核详情
  },
  {
    path: '/business/contractFileReview/contractFileReviewFactor',
    name: 'contractFileReviewFactor',
    meta: {
      cache: false
    },
    component: _import('business/contract-file-review/contract-file-review-factor') // 合同文件审核详情
  },
  // 合同文件制作
  {
    path: '/business/contractFileMake/contractFileMake',
    name: 'contractFileMake',
    meta: {
      cache: false
    },
    component: _import('business/contract-file-make/contract-file-make-main')
  },
  // 合同文件制作(签章)
  {
    path: '/business/contractFileMake/contractFileMakeSignature',
    name: 'contractFileMakeSignature',
    meta: {
      cache: false
    },
    component: _import('business/contract-file-make-signature/contract-file-make-signature-main')
  },
  // 还款登记
  {
    path: '/business/paymentRegistration',
    name: 'paymentRegistration',
    meta: {
      cache: false
    },
    component: _import('business/paymentRegistration/paymentRegistration')
  },
  // 在线签约---------------------------------------------------------
  {
    path: '/business/onlineSigning',
    name: 'onlineSigning',
    meta: {
      cache: false
    },
    component: _import('business/online-signing/online-signing')
  },
  // 申请签约 电子签章类型
  {
    path: '/business/applyOnlineSigning',
    name: 'applyOnlineSigning',
    meta: {
      cache: false
    },
    component: _import('business/online-signing/apply-online-signing')
  },
  // 申请签约 鲜章类型
  {
    path: '/business/applyOfflineSigning',
    name: 'applyOfflineSigning',
    meta: {
      cache: false
    },
    component: _import('business/online-signing/apply-offline-signing')
  },
  // 签约详情
  {
    path: '/business/signingDetails',
    name: 'signingDetails',
    meta: {
      cache: false
    },
    component: _import('business/online-signing/signing-details')
  },
  // 签约管理--------------------------------------------------------------
  {
    path: '/business/signManagement',
    name: 'signManagement',
    meta: {
      cache: false
    },
    component: _import('business/sign-management/sign-management-tabs')
  },
  // 签约管理>补签
  {
    path: '/business/signSupplement',
    name: 'signSupplement',
    meta: {
      cache: false
    },
    component: _import('business/sign-management/supplement-sign/supplement-sign')
  },
  // 签约文件>文件详情
  {
    path: '/business/signFileDetails',
    name: 'signFileDetails',
    meta: {
      cache: false
    },
    component: _import('business/sign-management/sign-file-details/sign-file-details')
  },
  // 预授信申请管理
  {
    path: '/business/preCreditApplicationManage',
    name: 'preCreditApplicationManage',
    meta: {
      cache: false
    },
    component: _import('business/pre-credit-application-management/pre-credit-application-list')
  },
  // 申请授信
  {
    path: '/business/applyForRufs',
    name: 'applyForRufs',
    meta: {
      cache: false
    },
    component: _import('business/pre-credit-application-management/apply-for-rufs')
  },
  // 正式授信管理
  {
    path: '/business/formalLetterManagement',
    name: 'formalLetterManagement',
    meta: {
      cache: false
    },
    component: _import('business/formal-letter-management/formal-letter-list')
  },
  // 推送产值
  {
    path: '/business/pushProduction',
    name: 'pushProduction',
    meta: {
      cache: false
    },
    component: _import('business/formal-letter-management/push-production')
  }
]
