import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import '@/plugin'
import '@/style/index.scss'
import Clipboard from 'clipboard'

import stateConfirm from '@/components/packages/state-confirm/state-confirm.js'
import { amountFilter } from '@/utils/auth/common.js'
// 引入图表组件
import * as echarts from 'echarts'
import 'echarts/map/js/china'
Vue.prototype.$stateConfirm = stateConfirm
Vue.prototype.$echarts = echarts
Vue.prototype.Clipboard = Clipboard
Vue.prototype.$amountFilter = amountFilter
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
