import Fileapi from './file/file'

const SUB_SYSTEM = 'finance'
// const url = process.env.VUE_APP_FILE_URL
// 融资业务资料清单管理
export const uploadfileListApi = new Fileapi(SUB_SYSTEM, 'filelist', 'http://192.168.138.30:38910')
// 合同文件制作
export const uploadcontractApi = new Fileapi(SUB_SYSTEM, 'contract/template', 'http://192.168.138.30:38910')
export const fileAPi = new Fileapi()
export const downAPi = new Fileapi()
