/**
 * rest 客户端
  * Created by YFSZ on 2019-02-21 09:44:57.
 * 自动生成
 */
import http from '@/utils/http/index'
/**
 * rest类，提供标准的rest接口调用api
 */
export default class Rest {
  /**
   * 构造函数
   * @param {String} subSys 子系统英文名（小写）
   * @param {String} module 模块英文名（小写）
   */
  constructor (subSys, module, url) {
    this.subSys = subSys
    this.module = module
    this.fileUrl = url
  }

  /**
   * 获取列表数据
   * @param {Object} params 请求对象
   * @returns {*|AxiosPromise} 响应
   */
  getList (params) {
    const $this = this
    return http({
      method: 'get',
      url: `api/${$this.subSys}/${$this.module}/list`,
      params
    })
  }

  /**
   * 获取分页数据
   * @param {Object} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  getPage (params) {
    const $this = this
    return http({
      showLoading: true,
      method: 'get',
      url: `api/${$this.subSys}/${$this.module}/page`,
      params
    })
  }

  /**
   * 根据id查询数据
   * @param {Number} id 主键id
   * @returns {*|AxiosPromise} 响应
   */
  get (keyId) {
    const $this = this
    return http({
      method: 'get',
      url: `api/${$this.subSys}/${$this.module}/${keyId}`
    })
  }

  /**
   * 根据表主键查找数据
   * @param {Object} params 主键id
   * @returns {*|AxiosPromise} 响应
   */
  find (params) {
    const $this = this
    return http({
      method: 'get',
      url: `api/${$this.subSys}/${$this.module}/find`,
      params
    })
  }

  /**
   * 创建对象
   * @param {Object} data 对象数据
   * @returns {*|AxiosPromise} 响应
   */
  create (data) {
    const $this = this
    return http({
      method: 'post',
      url: `api/${$this.subSys}/${$this.module}`,
      data
    })
  }

  /**
   * 批量创建对象
   * @param {Object} data 对象数据
   * @returns {*|AxiosPromise} 响应
   */
  creates (data) {
    const $this = this
    return http({
      method: 'post',
      url: `api/${$this.subSys}/${$this.module}/batch`,
      data
    })
  }

  /**
   * 删除数据
   * @param {Object} object 待删除对象
   * @returns {*|AxiosPromise} 响应
   */
  del (object) {
    const $this = this
    return http({
      showLoading: true,
      method: 'delete',
      url: `api/${$this.subSys}/${$this.module}/${object.keyId}`
    })
  }

  /**
   * 批量删除数据
   * @param {Object} ids 待删除对象
   * @returns {*|AxiosPromise} 响应
   */
  dels (ids) {
    const $this = this
    return http({
      method: 'delete',
      url: `api/${$this.subSys}/${$this.module}/batch`,
      params: ids
    })
  }

  /**
   * 修改模型对象
   * @param {Number} keyId 主键
   * @param {Object} data 模型对象
   * @returns {*|AxiosPromise} 响应
   */
  update (keyId, data) {
    const $this = this
    return http({
      showLoading: true,
      method: 'put',
      url: `api/${$this.subSys}/${$this.module}/${keyId}`,
      data
    })
  }
}
