const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  {
    path: '/workbench/technological/todo',
    name: 'todo',
    component: _import('workbench/my-workbench/my-todo'), // 待办
    meta: {
      cache: false
    }
  },
  {
    path: '/workbench/technological/done',
    name: 'done',
    component: _import('workbench/my-workbench/my-done'), // 已办
    meta: {
      cache: false
    }
  }
]
