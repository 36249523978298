import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout'
import loginLayout from '@/components/login-layout/login-layout.vue'
import { checkRoutesConfig } from './router-check'

const _import = require('./_import_' + process.env.NODE_ENV)
Vue.use(VueRouter)

let asyncRouterMap = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: _import('dashboard/dashboard'),
    meta: {
      cache: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: _import('home/home'),
    meta: {
      cache: false
    }
  },
  {
    path: '/401',
    name: '401',
    component: _import('error-page/401')
  },
  {
    path: '/404',
    name: '404',
    component: _import('error-page/404')
  }
]

const routerContext = require.context('./', true, /index\.js$/)

routerContext.keys().forEach((route) => {
  if (route.startsWith('./index')) {
    return
  }
  const routeModule = routerContext(route)
  asyncRouterMap = [...asyncRouterMap, ...(routeModule.default || routeModule)]
})

const routes = [
  {
    path: '/',
    component: Layout,
    children: asyncRouterMap
  },
  {
    path: '/',
    component: loginLayout,
    children: [
      {
        path: '/login',
        component: _import('login/login-main')
      },
      {
        path: '/register',
        component: _import('login/register')
      }
    ]
  }

  // { path: '/indentityAuth', component: _import('indentity-auth/main') },
  // 404页面配置必须放在最后，当所有路由都无法匹配上的时候，跳入404页面
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
checkRoutesConfig(routes)
export default router
