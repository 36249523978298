<template>
  <div class="layout-tags">
    <div
      tag="div"
      :to="item.path"
      class="tag-child"
      v-for="item in tags"
      :key="item.path"
      :class="{ active: item.path === currentPath }"
      @click="handleAction(item)"
      @contextmenu.prevent="openMenu(item, $event)"
    >
      <span>{{ item.menuName }}</span>
      <i class="el-icon-close" @click.stop.prevent="handleDelItem(item)"></i>
      <!-- <span class="icon" @click.stop.prevent="handleDelItem(item)">

      </span> -->
    </div>
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li @click.stop="refreshSelectedTag(selectedTag)">刷新</li>
      <li @click.stop="closeSelectedTag(selectedTag)">关闭本页标签</li>
      <li @click.stop="closeOthersTags">关闭其他标签</li>
      <li @click.stop="closeAllTags(selectedTag)">关闭所有标签</li>
    </ul>
  </div>
</template>

<script>
import { MAX_CACHE } from '@/config'

export default {
  computed: {
    menusList () {
      return this.$store.state.sys.menusList
    },
    pathLists () {
      const res = []
      this.menusList.forEach(it => {
        const { path, hidden } = it
        if (!hidden) {
          res.push(path)
        }
      })
      res.push('/')
      return res
    }
  },

  data () {
    return {
      currentPath: '/',
      tags: [],
      // 用来缓存所有菜单的配置项
      caches: [],
      left: 0,
      top: 0,
      selectedTag: {},
      delCurrentPath: '',
      visible: false,
      lastPath: '',
      tagNames: []
    }
  },
  watch: {
    visible (value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
    tagNames: {
      handler (val) {
        this.$store.dispatch('sys/changeCaches', [...val])
      },
      immediate: true
    }
  },
  mounted () {
    this.createWatcher()
  },

  methods: {
    closeMenu () {
      this.visible = false
    },
    // 刷新全部还是一个？
    refreshSelectedTag ({ path, name }) {
      const { path: current } = this.$route
      const isCache = this.tagNames.includes(name)
      if (isCache) {
        const delIds = this.tagNames.findIndex(it => it === name)
        this.tagNames.splice(delIds, 1)
      }

      this.$nextTick(() => {
        this.closeMenu()
        // 不同页面刷新,跳转到对应页面去刷新
        if (current !== path) {
          this.$router.push({
            path
          })
          isCache && this.tagNames.push(name)
        } else {
          this.$store.dispatch('sys/changeFresh', false)
          this.$nextTick(() => {
            this.$store.dispatch('sys/changeFresh', true)
            isCache && this.tagNames.push(name)
          })
        }
      })
    },

    closeSelectedTag () {
      this.handleDelItem(this.selectedTag)
      this.closeMenu()
    },

    // 关闭其它标签
    closeOthersTags () {
      const { name } = this.selectedTag
      this.caches = [name]

      if (this.tagNames.includes(name)) {
        this.tagNames = [name]
      } else {
        this.tagNames = []
      }

      this.tags = [this.selectedTag]
      this.closeMenu()
    },
    // 关闭所有
    closeAllTags () {
      this.caches = []
      this.tagNames = []
      this.tags = []
      this.closeMenu()
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/'
        })
      }
    },
    /** 兼容zoom 属性添加后的问题 */
    openMenu (tag, e) {
      const html = document.querySelector('html')
      const { zoom } = window.getComputedStyle(html)
      const scale = zoom ? 1 / zoom : 1
      const menuMinWidth = 105 * scale
      const offsetLeft = zoom
        ? this.$el.getBoundingClientRect().left * zoom
        : this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 10 * scale // 15: margin right
      if (left > maxLeft) {
        this.left = maxLeft * scale
      } else {
        this.left = left * scale
      }

      this.top = e.clientY * scale

      this.visible = true
      this.selectedTag = tag
    },
    // 关闭子菜单
    closeChild ({ path, name }) {
      if (this.tags.length === 1) {
        this.$router.push({ path: '/' })
      } else {
        const jumpName = this.caches.slice(-2)[0]
        const current = this.tags.find(it => it.name === jumpName)
        this.$router.push({
          path: current?.path || '/'
        })
      }
      // 清楚记录
      this.delCurrentPath = ''
    },
    // 删除操作
    handleDelItem ({ path, name }) {
      // 关闭父级菜单 子菜单关闭操作
      if (path === this.delCurrentPath) {
        this.closeChild(...arguments)
        this.removePath(path, name)
        // 关闭tag 是当前页面
      } else {
        // 只有首页一个 tag 不做处理
        if (this.tags.length === 1 && this.$route.path === '/') {
          return
        }

        if (this.tags.length === 1) {
          this.$router.push({
            path: '/'
          })
        } else if (this.$route.path === path) {
          const jumpName = this.caches.slice(-2)[0]
          const current = this.tags.find(it => it.name === jumpName)
          this.$router.push({
            path: current?.path || '/'
          })
        }
        // 先跳转再做删除
        this.removePath(path, name)
      }
    },

    handleAction ({ path }) {
      const { path: current } = this.$route
      if (current !== path) {
        this.$router.push({
          path
        })
      }
    },

    // 清楚当前记录
    removePath (path, name) {
      // tags 关闭
      const index = this.tags.findIndex(it => it.path === path)
      if (index > -1) {
        this.tags.splice(index, 1)
      }
      // tagsName  keep-alive 缓存问题
      const nameIndex = this.tagNames.findIndex(it => it === name)
      if (nameIndex > -1) {
        this.tagNames.splice(nameIndex, 1)
      }
      // 缓存删除问题
      const cacheIndex = this.caches.findIndex(it => it === name)
      if (cacheIndex > -1) {
        this.caches.splice(cacheIndex, 1)
      }
    },

    createWatcher () {
      this.createCache()
      this.$watch('$route', function (newRoute, old) {
        this.lastPath = old.path
        this.createCache()
      })
    },
    /*
      1. 保存没有tag 路由切换逻辑
      2. 保存进入tag 的上一个路由
    */
    saveNoTagPath () {
      if (this.tags.length === 0) {
        this.tags.push({
          path: '/',
          name: 'Home',
          menuName: '首页'
        })
      } else {
        this.delCurrentPath = this.lastPath
      }
    },

    createCache () {
      const {
        path,
        name,
        meta: { cache }
      } = this.$route
      this.currentPath = path
      // 不是菜单目录  不做tag展示处理
      if (!this.pathLists.includes(path)) {
        return this.saveNoTagPath()
      }
      this.delCurrentPath = ''
      const menuName = this.getPathName(path)
      // 是否缓存
      const pathCache = typeof cache === 'undefined' || cache === true

      // 返回上一页按钮历史记录
      const routerList = { path, menuName }
      sessionStorage.setItem('routerList', JSON.stringify(routerList))
      // 1. 如果没有在tag中，添加进去
      // 2. 如果有 进行缓存的排序
      if (this.tags.some(it => it.path === path)) {
        this.handleCacheSort(name)
      } else {
        this.handleCacheAdd(path, menuName, name, !!pathCache)
      }
    },

    // 处理缓存顺序问题
    handleCacheSort (name) {
      const nameIndex = this.caches.findIndex(it => it === name)
      this.caches.splice(nameIndex, 1)
      this.caches.push(name)
    },

    // 修改缓存记录
    handleCacheAdd (path, menuName, name, pathCache) {
      // 如果超出最大缓存 清空最早的数据
      if (this.tags.length >= MAX_CACHE && MAX_CACHE) {
        const delName = this.caches[0]

        // 删除tag记录
        const removeIndex = this.tags.findIndex(it => it.name === delName)
        if (removeIndex > -1) {
          this.tags.splice(removeIndex, 1)
        } else {
          this.tags.splice(0, 1)
        }

        // 删除cache 记录 直接删除第一个（访问历史最老的一个）
        this.caches.splice(0, 1)

        // 如果缓存超出最大范围 执行删除
        if (this.tagNames >= MAX_CACHE) {
          const tagNamesIndex = this.tagNames.findIndex(it => it === delName)
          if (tagNamesIndex > -1) {
            this.tagNames.splice(tagNamesIndex, 1)
          }
        }
      }

      if (pathCache) {
        this.tagNames.push(name)
      }

      this.caches.push(name)

      this.tags.push({ name, menuName, path })
    },

    getPathName (path) {
      if (path === '/') {
        return '首页'
      }
      const item = this.menusList.find(it => path === it.path)
      return item?.menuName
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-tags {
  height: 44px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  // border-bottom: 1px solid #f8efd9;
  padding: 8px 16px;
  .tag-child {
    display: inline-block;
    height: 28px;
    background-color: #ebf1ff;
    border-radius: 4px;
    text-align: center;
    border-right: 1px solid #f2eee4;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 8px 5px 8px;
    position: relative;
    user-select: none;
    color: #285ed7;

    i {
      margin-left: 6px;
    }
    &.active {
      background-color: #4a7cf0;
      color: white;
    }
    // 第二个开始
    &:nth-child(n + 2) {
      margin-left: 8px;
    }
  }
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
</style>
