const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 项目信息
  {
    path: '/assetsManage/projectInformation',
    name: 'projectInformation',
    component: _import('assets-manage/project-information/project-information'),
    meta: {
      cache: false
    }
  },
  // 付款单详情
  {
    path: '/assetsManage/PaymentOrderDetails',
    name: 'PaymentOrderDetails',
    component: _import('assets-manage/payment-order-details/payment-order-details')
  },
  // 付款单信息
  {
    path: '/assetsManage/paymentOrderInformation',
    name: 'paymentOrderInformation',
    component: _import('assets-manage/payment-order-Information/payment-order-Information'),
    meta: {
      cache: false
    }
  },
  { // 访谈纪要
    path: '/assetsManage/interviewMinutes',
    name: 'interviewMinutes',
    component: _import('assets-manage/interviewMinutes/interviewMinutesPage')
  },
  { // 产值变化查询
    path: '/assetsManage/assetChangeQuery',
    name: 'assetChangeQuery',
    component: _import('assets-manage/asset-change-query/asset-change-query'),
    meta: {
      cache: false
    }
  },
  { // 产值变化明细
    path: '/assetsManage/assetChangeDetail',
    name: 'assetChangeDetail',
    component: _import('assets-manage/asset-change-detail/asset-change-detail'),
    meta: {
      cache: false
    }
  }
]
