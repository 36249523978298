<template>
 <div> <div class="lay-header">
    <i
      class="iconfont iconzhankai2 open_flag"
      :class="{open: flag}"
      @click="changeOpenFlag"
    ></i>
    <!-- <i class="iconfont iconquanping head_btn all_flag" v-show="!isFullscreen" @click="changeScreen"></i> -->
    <!-- <i class="iconfont icontuichuquanping head_btn all_flag" v-show="isFullscreen" @click="changeScreen"></i> -->
    <user-info></user-info>
  </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import { mapActions, mapState } from 'vuex'
import userInfo from './components/user-info.vue'
export default {
  components: { userInfo },
  methods: {
    ...mapActions({
      changeOpenFlag: 'sys/changeOpenFlag'
    }),
    changeScreen () {
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    change () {
      this.isFullscreen = screenfull.isFullscreen
    },
    init () {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy () {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change)
      }
    }
  },
  data () {
    return {
      isFullscreen: false
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.destroy()
  },
  computed: {
    ...mapState({
      flag: state => state.sys.openFlag
    })
  }
}
</script>

<style>

</style>
