// import { testMenus } from '@/config/dev-menus.config'

// import { DEFAULT_MENUS } from '@/config/constant'

export const buildMenusData = (menus, routeMapping) => {
  if (process.env.NODE_ENV === 'development') {
    // 开发环境新加的配置数据处理
    // menus = [...DEFAULT_MENUS, ...menus]
  }
  const returnMenus = []
  const childrenMenus = []
  menus.forEach(function (element) {
    const menu = {
      id: element.menuId,
      keyId: element.menuId,
      title: element.menuName,
      component: element.component,
      path: element.path,
      params: null,
      query: {},
      parentId: element.parentKid,
      leaf: true,
      hidden: false,
      icon: element.icon,
      children: [],
      menuCode: element.menuCode,
      operationList: []
    }
    if (!element.component) {
      element.component = routeMapping[element.path]
    }
    if (element.params) {
      menu.params = JSON.parse(element.params)
    }
    if (element.query) {
      menu.query = JSON.parse(element.query)
    }
    if (element.hidden !== null && element.hidden !== undefined) {
      menu.hidden = element.hidden
    }
    if (element.menuCode !== null && element.menuCode !== undefined) {
      menu.menuCode = element.menuCode
    }
    if (
      element.opAuthorityDTOList !== null &&
      element.opAuthorityDTOList !== undefined
    ) {
      menu.operationList = element.opAuthorityDTOList
    }
    if (element.parentKid === '0') {
      returnMenus.push(menu)
    } else {
      childrenMenus.push(menu)
    }
  }, this)
  returnMenus.forEach(parentMenu => {
    getChildrenMenu(parentMenu, childrenMenus)
  })
  return returnMenus
}

function getChildrenMenu (menu, menus) {
  let flag = false
  menus.forEach(it => {
    if (it.parentId === menu.keyId) {
      menu.children.push(it)
      flag = true
      getChildrenMenu(it, menus)
    }
  })
  if (flag) {
    menu.leaf = false
  }
}
