<template>
  <base-dialog
    :visible.sync="visible"
    @submit="submit"
    :title="title"
    submitText="切换"
    width="400px"
    :autoHeight="true"
    class="change-app-dialog"
  >
    <base-form
      ref="formRef"
      :componentList="componentList"
      :showBtns="false"
      :formAttrs="{
        model: formData,
      }"
    ></base-form>
  </base-dialog>
</template>
<script>
import { getAppLists } from '@/api/sys/user'
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getSysId, getTenant, getToken } from '@/utils/auth/auth-storage'
import { setUrlParas } from '@/utils/devUtils'

export default {
  components: {
    baseDialog,
    BaseForm
  },
  data () {
    return {
      title: '切换子系统',
      visible: false,
      formData: {
        appId: ''
      },
      appList: []
    }
  },
  computed: {
    componentList () {
      return [
        {
          label: '',
          prop: 'appId',
          span: 24,
          tag: BaseSelect,
          attrs: {
            options: this.appList,
            selectedField: ['appId', 'appName']
          }
        }
      ]
    }
  },
  created () {
    this.initAppList()
  },
  methods: {
    initAppList () {
      getAppLists().then(res => {
        const appList = res.data?.[0]?.appLists || []
        const thisAppId = getSysId()
        this.appList = appList.filter(s => s.appId !== thisAppId)
      })
    },
    submit () {
      if (!this.formData.appId) {
        return this.warning('请选择子系统')
      }
      const app = this.appList.find(s => s.appId === this.formData.appId)
      if (app && app.website) {
        location.href = setUrlParas(app.website, {
          token: getToken(),
          tenantId: getTenant(),
          sysId: app.appId
        })
      } else {
        this.error('系统配置错误，请联系管理员！')
      }
    },
    open () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.change-app-dialog{
  /deep/.el-dialog {
    height: auto;
  }
}
</style>
