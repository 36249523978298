/**
 * 开发相关函数
 * Created by YFSZ on 2019-02-21 09:44:57.
 * 自动生成
 */
import store from '@/store'
import { Message } from 'element-ui'

/**
 * 获取header方法
 * @returns {*} headers
 */
export function getHeaders (process) {
  return {
    Authorization: 'Bearer ' + store.getters.token,
    token: store.getters.token || '',
    tenantId: store.getters.tenantId || '2106281026588400001',
    client: store.getters.sysId || '2106281249288910001',
    operatorId: store.getters.userId || ''
  }
}

export function downFile (res, name, inlineName) {
  const reader = new FileReader()
  reader.readAsText(res.data, 'utf-8')
  reader.onload = function () {
    try {
      const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
      if (jsonData.code) {
        Message({
          message: jsonData.message,
          type: 'error',
          duration: 3 * 1000
        })
      }
    } catch (err) { // 解析成对象失败，说明是正常的文件流
      downFileStream(res, name, inlineName)
    }
  }
}
// 处理文件名中特殊符号
function encodeSearchKey (key) {
  const encodeArr = [{
    code: '%',
    encode: '%25'
  }, {
    code: '?',
    encode: '%3F'
  }, {
    code: '#',
    encode: '%23'
  }, {
    code: '&',
    encode: '%26'
  }, {
    code: '=',
    encode: '%3D'
  }]
  return key.replace(/[%?#&=]/g, ($, index, str) => {
    for (const k of encodeArr) {
      if (k.code === $) {
        return k.encode
      }
    }
  })
}

function downFileStream (res, name, inlineName) {
  if (!res) {
    return
  }
  let filename = name || getPara(res.headers['content-disposition'], 'filename')
  filename = filename.replace(new RegExp('"', 'gm'), '')
  filename = encodeSearchKey(filename)
  filename = window.decodeURIComponent(filename)
  if (inlineName && !name) {
    const a = filename.split('.')
    const newName = `${a[0]}${inlineName}.${a[1]}`
    filename = newName
  }
  if ('msSaveOrOpenBlob' in navigator) {
    // edge和ie的下载方法
    window.navigator.msSaveOrOpenBlob(new Blob([res.data]), filename)
  } else {
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
  }
}

/**
 * 获取字符串中的参数
 * @param {String} str 总字符串（'name1=xxx;name2=xxx'）
 * @param {String} name 要筛选的字段name1
 * @returns {string} 返回的字段xxx
 */
export function getPara (str, name) {
  // 查询检索的值
  const search = name + '='
  // 返回值
  let ret = ''
  if (str && str.length > 0) {
    const s = decodeURIComponent(str)
    let sd = s.indexOf(search)
    if (sd !== -1) {
      sd += search.length
      let end = s.indexOf(';', sd)
      if (end === -1) {
        end = s.length
      }
      ret = s.substring(sd, end)
    }
  }
  return ret
}

// url参数解析
export function getUrlParams (url) {
  const paraIndex = url.indexOf('?')
  if (paraIndex === -1) {
    return null
  }
  const params = {}
  url = url.substr(paraIndex + 1, url.length)
  const paras = url.split('&')
  paras.forEach((element) => {
    const para = element.split('=')
    params[para[0]] = decodeURIComponent(para[1])
  })
  return params
}

/**
 * 添加url参数
 * @param {*} url
 * @param {*} data
 * @returns
 */
export function setUrlParas (url, data) {
  Object.keys(data).forEach(k => {
    if (/\?.*=/.test(url)) {
      url += `&${k}=${data[k]}`
    } else {
      url += `?${k}=${data[k]}`
    }
  })
  return url
}

/**
 * 跳转到登录门户
 * @returns {Void} void
 */
export function redirctLogin () {
  // 登录成功后始终跳转到/index.html, 以确保非nginx部署时出现404的问题
  // 构造登录成功后的跳转地址
  let locationUri = window.location.origin
  if (locationUri === undefined || locationUri === null) {
    // 协议 + 主机（域名）
    locationUri = window.location.protocol + '//' + window.location.host
  }
  window.open(process.env.VUE_APP_LOGIN_URL + '?redirct=' + locationUri, '_self')
}
