<!-- 按钮组件
  计算将按钮组件搞到一起，只需要传入对应的按钮类型就可以
   -->
<template>
  <component :is="componentId" v-bind="currentAttrs" v-on="currentlisteners">
  </component>
</template>

<script>
import UploadBtn from './../upload-btn/upload-btn.vue'
import authButton from './../auth-button/auth-button.vue'
import TempDownload from './../temp-download/temp-download.vue'

const btnMap = {
  upload: 'UploadBtn',
  downloadTemp: 'TempDownload'
}
const defaultAttrs = {
  type: 'primary'
}

const buildIconName = (name) =>
  !name.includes('iconfont') ? name : `iconfont ${name}`

export default {
  name: 'baseButton',
  components: { UploadBtn, TempDownload },
  props: {
    btnType: {
      type: String
    }
  },
  data () {
    return {}
  },
  computed: {
    currentlisteners () {
      return Object.assign({}, {
        click: (...arg) => {
          this.$emit('click', ...arg)
        },
        dbclick: (...arg) => {
          this.$emit('dbclick', ...arg)
        }
      })
    },
    componentId () {
      if (Object.keys(btnMap).includes(this.btnType)) {
        return btnMap[this.btnType]
      } else {
        return authButton
      }
    },
    currentAttrs () {
      const attr = this.$attrs
      if (attr.icon) {
        attr.icon = buildIconName(attr.icon)
      }
      return { ...defaultAttrs, ...attr, btnType: this.btnType }
    }
  },

  mounted () {},

  methods: {}
}
</script>
