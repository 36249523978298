<!-- 上传组件 -->
<template>
  <el-upload
    class="upload-demo"
    v-bind="$attrs"
    v-on="$listeners"
    :before-upload="beforeUploadFunc"
    :action="action"
    :accept="currentFileAccept"
    :showFileList="showFileList"
  >
    <auth-button size="small" :label="label" v-bind="$attrs"></auth-button>
  </el-upload>
</template>

<script>
import authButton from './../auth-button/auth-button.vue'
import { fileMimeMap } from '@/constants/file-mime'
export const getSize = (size) => {
  var data = ''
  if (size < 0.1 * 1024) { // 如果小于0.1KB转化成B
    data = size.toFixed(2) + 'B'
  } else if (size < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
    data = (size / 1024).toFixed(2) + 'KB'
  } else if (size < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
    data = (size / (1024 * 1024)).toFixed(2) + 'MB'
  } else { // 其他转化成GB
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }
  var sizestr = data + ''
  var len = sizestr.indexOf('.')
  var dec = sizestr.substr(len + 1, 2)
  if (dec === '00') { // 当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
  }
  return sizestr
}

export default {
  name: 'uploadBtn',
  components: { authButton },
  props: {
    label: {
      type: String,
      default: '导入模板'
    },
    accept: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: 'https://jsonplaceholder.typicode.com/posts/'
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },

  computed: {
    currentFileAccept () {
      const res = []
      const acceptArr = this.accept.split(',')
      acceptArr.forEach(type => {
        res.push(fileMimeMap[type])
      })
      return res.length ? res.join(',') : ''
    }
  },

  methods: {
    beforeUploadFunc (file) {
      if (typeof this.$attrs.beforeUpload === 'function') {
        return this.$attrs.beforeUpload(file)
      }
      const { accept, maxSize } = this
      if (accept && accept !== '*') { // 校验格式
        const namesArr = file.name.split('.')
        const type = (namesArr[namesArr.length - 1]).toLowerCase()
        if (!accept.includes(type)) {
          this.warning(`文件格式不正确，只能上传格式为【${this.accept}】的文件`)
          return false
        }
      }
      if (maxSize && maxSize > 0 && file.size > maxSize) {
        this.warning(`上传文件不超过${getSize(maxSize)}`)
        return false
      }
      if (file.size === 0) {
        this.warning('不能上传空文件，请重新上传')
        return false
      }
      const fileArr = file.name.split('.')
      fileArr.pop()
      console.log(fileArr.join(',').length)
      if (fileArr.join(',').length > 80) {
        this.warning('文件名长度上限为80个字符')
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" src="./upload-btn.scss" scoped></style>
