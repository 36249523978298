<!-- 模板下载按钮 -->
<template>
  <auth-button
    v-bind="$attrs"
    v-on="currentListeners"
    :label="label"
  ></auth-button>
</template>

<script>
// import fileApi from '@/api/fileApi'

// import { downFile } from '@/utils/devUtils'

import AuthButton from './../auth-button/auth-button.vue'

export default {
  name: 'tempDownload',
  components: { AuthButton },
  props: {
    label: {
      type: String,
      default: '模板下载'
    },
    templateCode: null
  },
  computed: {
    currentListeners () {
      const _this = this
      return Object.assign({}, this.$listeners, {
        click: function () {
          if (!_this.templateCode) {
            return _this.warning('请传入模板下载参数')
          } else {
            _this.loadTemp()
          }
        }
      })
    }
  },

  mounted () {},

  methods: {
    loadTemp () {
      // const params = {
      //   keyId: this.templateCode
      // }
      // fileApi.file.download(params).then(res => {
      //   downFile(res)
      // }).catch(e => {
      //   this.error(e)
      // })
      // fileApi.file
      //   .downloadByTemplateCode({
      //     templateCode: this.templateCode
      //   })
      //   .then(res => {
      //     downFile(res)
      //   })
      //   .catch(e => {
      //     this.error(e)
      //   })
    }
  }
}
</script>
<style lang="scss" scoped></style>
