
<template>
  <!-- 登录页面模块 -->
  <div class="login-page">
    <router-view />
    <!-- <section>
      <router-view />
      <footer>
          Copyright &copy;2021 {{websiteText}}
          <a href="https://beian.miit.gov.cn" title="网站备案" target="_blank"
            >网站备案号：{{ websiteCode }}</a
          >
      </footer>
    </section> -->
  </div>
</template>

<script>
export default {
  name: 'loginLayout',
  data () {
    return {
      logoImage: require('@/assets/logo-login.png'),
      websiteCode: process.env.VUE_APP_WEBSITE_CODE,
      websiteText: process.env.VUE_APP_WEBSITE_NAME
    }
  },
  computed: {
    // websiteCode () {
    //   return '粤ICP备15014124号'
    // },
    // websiteText () {
    //   return '深圳市佑荣信息科技有限公司 版权所有'
    // }
  }
}
</script>

<style lang="scss" src='./login-layout.scss' scoped></style>
