<template>
  <div class="user-info">
    <!-- <div class="photos"></div> -->
    <span class="username">您好,{{ username }}</span>

    <el-dropdown @command="handleCommand" trigger="click">
      <!-- <div class="link"></div> -->
      <i class="el-icon-arrow-down"></i>
      <el-dropdown-menu slot="dropdown" class="user-dropdown">
        <el-dropdown-item command="changeApp">切换子系统</el-dropdown-item>
        <el-dropdown-item command="resetPassword">修改密码</el-dropdown-item>
        <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <change-password
      ref="resetPassword"
      @refresh="refresh"
      title="修改密码"
    ></change-password>
    <!-- 切换子系统 -->
    <change-app ref="changeAppRef" />
  </div>
</template>

<script>
// import baseButton from '@/components/common/button/base-button/base-button.vue'
import { removeToken } from '@/utils/auth/auth-storage'
import changePassword from '@/pages/basic-data-management/user-management/components/change-password.vue'
import changeApp from './changeApp.vue'
export default {
  components: { changePassword, changeApp },
  data () {
    return {}
  },
  computed: {
    username () {
      return this.$store.state.user.userInfo.nickName
    }
  },
  methods: {
    // 下拉框点击
    handleCommand (command) {
      if (command === 'loginOut') {
        this.handleLoginOut()
      } else if (command === 'resetPassword') {
        this.$refs.resetPassword.open({
          userId: this.$store.state.user.operateId
        })
      } else if (command === 'changeApp') {
        // 切换子系统
        this.$refs.changeAppRef.open()
      }
    },

    refresh () {
      this.$alert('密码已修改，请重新登录', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        callback: () => {
          this.handleLoginOut()
        }
      })
    },

    handleLoginOut () {
      removeToken()
      this.$router.replace({
        path: '/login'
      })
    }
  }
}
</script>
<style lang="scss">
.user-dropdown {
  // padding: 0 !important;
  // // margin-top: 25px !important;
  // margin-left: 35px !important ;
  // .popper__arrow {
  //   display: none !important;
  // }
  // .el-dropdown-menu__item {
  //   height: 40px;
  //   line-height: 40px !important;
  //   color: #FFFFFF !important;
  //   &:hover {
  //     background-color: #4A7CF0 !important;
  //   }
  // }
}
</style>
<style lang="scss" scoped>
.username {
  font-size: 14px;
  font-weight: 400;
  color: #16211d;
  padding-left: 11px;
}
i.el-icon-arrow-down {
  color: #16211d;
  margin-left: 10px;
}
.photos {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #7586b4;
  border-radius: 50%;
  margin-right: 10px;
  background-image: url("../../assets/layout/photo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.link {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #b2662f;
  cursor: pointer;
}
</style>
