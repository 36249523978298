import Vue from 'vue'
import Router from '@/router'
import store from '@/store'
// 是否开启权限判定
const isAuth = true
/**
 * 权限判定
 * 首先是参数
 * 再有就是路由
 * 状态判定由业务逻辑自行处理
 * @param {*} val
 * @example
 *  <el-row v-if="payStatus === '1'" v-perm="'paymentbtns'">
 *  传入字符串 要么是变量 要么加双层引号处理
 */
export const hasPermission = val => {
  if (!isAuth) {
    return true
  }
  const permsConfig = store.getters.authPerms
  const currentPath = Router.currentRoute.path
  if (permsConfig[currentPath] && permsConfig[currentPath].includes(val)) {
    return true
  } else {
    return false
  }
}

Vue.directive('perm', {
  bind: (el, binding) => {
    if (!hasPermission(binding.value)) {
      if (el && el.parentNode) {
        el.parentNode.removeChild(el)
      } else {
        el.style.display = 'none'
      }
    }
  }
})
