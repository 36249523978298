<template>
  <!-- <base-button
    v-bind="$attrs"
    class="btn-text__inline"
    v-on="currentListeners"
    type="text"
    :label="content"
    icon=""
  ></base-button> -->
  <div style="display: inline">
    <el-tooltip
      class="item"
      effect="dark"
      :disabled="disabled"
      :enterable="false"
      :content="content"
      placement="top"
      :hide-after="3000"
      v-if="content"
    >
      <base-button
        v-bind="$attrs"
        v-on="currentListeners"
        type="text"
      ></base-button>
    </el-tooltip>
    <base-button
      v-bind="$attrs"
      v-on="currentListeners"
      type="text"
      v-else
    ></base-button>
  </div>
</template>
<script>
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
import baseButton from './../base-button/base-button.vue'
export default {
  name: 'iconButton',
  components: { baseButton },
  props: {
    content: String,
    disabled: {
      type: Boolean,
      default: false
    },
    btnType: {
      type: String,
      default: 'text'
    },
    templateCode: null
  },
  data () {
    return {}
  },
  computed: {
    currentListeners () {
      const _this = this
      if (this.btnType === 'download') {
        return Object.assign({}, this.$listeners, {
          click: function () {
            if (!_this.templateCode) {
              return _this.warning('请传入下载参数')
            } else {
              _this.loadTemp()
            }
          }
        })
      } else {
        return Object.assign({}, this.$listeners)
      }
    }
  },
  methods: {
    loadTemp () {
      const params = {
        keyId: this.templateCode
      }
      downAPi
        .download(params)
        .then(res => {
          downFile(res)
        })
        .catch(e => {
          this.error(e)
        })
    }
  }
}
</script>
<style lang="scss" src="./icon-button.scss" scoped></style>
