const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 账户管理
  {
    path: '/parameterManage/parameterSubsidiary',
    name: 'parameterSubsidiary',
    component: _import('parameter-manage/parameter-subsidiary/parameter-subsidiary'),
    meta: {
      cache: false
    }
  },
  // 风控分配汇总
  {
    path: '/parameterManage/summaryRiskControl',
    name: 'summaryRiskControl',
    component: _import('parameter-manage/summary-risk-control/summary-risk-control'),
    meta: {
      cache: false
    }
  }
]
