<template>
  <div class="cu-dialog">
    <div class="padding-xl dialogHeight">
      <div class="caption bold">
        数据查询授权书
      </div>
      <div class="bold indent">
        为了维护您的合法权益，请您特别注意本协议中黑体加粗的条款及术语，关注相应权利、义务，若您有任何疑问请及时咨询稳诚一方供应链管理服务平台。如您对本协议有任何意见、建议、疑问或需要投诉，您可以通过客服与我们联系咨询，我们会及时处理您的意见、建议、投诉及相关问题。
      </div>
      <div class="indent margin_top">
        本人/本单位授权贵司<span class="bold">查询、了解、保存和使用</span>本人/本单位与金地集团及其下属公司<span class="bold">信用信息（即身份识别信息、设备信息、IP信息，以及企业工商司法信息、税务信息、增值税发票信息、结算流水账户信息、政务信息、票据信息、采购/中标信息、合同信息、应收账款信息、产值信息、结算信息）</span>，并向数据来源方反馈基于合作信用信息形成的<span class="bold">业务相关数据（即金融机构信息、放还款信息、合作产品要素信息）</span>。
      </div>
      <div class="bold indent">
        在阅读并同意本授权书后，即表示本人/本单位已理解并同意该授权书。
      </div>
      <div class="bold indent">
        本人/本单位声明：贵司已依法向本人/本单位提示了上述条款（特别是黑体加粗部分），应本人/本单位要求对相关概念、内容及法律效果做了说明，本人/本单位已经知悉并理解上述条款。
      </div>

    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.dialogHeight {
  text-align: initial;
  padding: 20px;
  color: #333;
  font-size: 15px;
  line-height: 28px;
}
.dialogHeight .caption {
  margin-bottom: 30px !important;
  font-size: 20px;
  text-align: center;
}
.dialogHeight .bold {
  font-weight: bold;
}
.dialogHeight .indent {
  text-indent: 2em;
}
.dialogHeight .margin_top {
  margin-top: 20px;
}
.dialogHeight view {
  margin-bottom: 18px;
}
.dialogHeight .text_right {
  text-align: right;
}
.dialogHeight .text_center {
  text-align: center;
}
.privacy view {
  text-indent: 2em;
}
.privacy .money {
  text-indent: initial;
  margin-top: 10px;
}
.privacy .money .cu-form-group {
  margin-top: 10px;
}

.privacy .caption {
   text-indent: initial;
}
</style>
