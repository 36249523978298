// 客户关系管理
const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 访谈纪要管理
  {
    path: '/CRM/interviewMinutesManagement',
    name: 'interviewMinutesManagement',
    component: _import('customer-relation-management/interview-minutes-management/interview-minutes-management'),
    meta: {
      cache: false
    }
  },
  // 访谈纪要制作
  {
    path: '/CRM/interviewMinutesMake',
    name: 'interviewMinutesMake',
    component: _import('customer-relation-management/interview-minutes-management/interview-minutes-make'),
    meta: {
      cache: false
    }
  },
  // 访谈纪要企业信息查看
  {
    path: '/CRM/enterpriseInformation',
    name: 'enterpriseInformation',
    component: _import('customer-relation-management/interview-minutes-management/production-type/enterprise-information'),
    meta: {
      cache: false
    }
  }
]
