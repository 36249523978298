import fileMimeJson from './mime-type.json'

const fileMimeMap = {}
fileMimeJson.forEach((it) => {
  for (const [key, value] of Object.entries(it)) {
    fileMimeMap[key] = value
  }
})

export { fileMimeMap }
