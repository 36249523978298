/**
 * 常量配置处
 */
// http 调用时显示日志
export const HTTP_LOG_FLAG = false

export const APP_CODE = 'wsf_app'

// 最大缓存数量
export const MAX_CACHE = 10

export const DEFAULT_MENUS = [{
  appKid: '09090901',
  component: 'home',
  hidden: false,
  icon: '',
  leaf: true,
  menuCode: 'homepage',
  menuDesc: '',
  menuId: '2109061542383960001',
  menuName: '首页1',
  menuView: '',
  params: '',
  parentKid: '0',
  path: '/',
  prefix: 'http://',
  query: '',
  sortNum: 0,
  target: '_self'
}]

// 数据授权资金配置列表
export const DATA_AUTH_ORG_LIST = {
  '2208121420261270001': '邮储银行数据查询授权书'
}

// 邮储银行资金方默认keyId
export const PSBC_KEY_ID = '2208121420261270001'
