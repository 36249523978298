<!-- 权限按钮 -->

<script>
export default {
  name: 'authButton',
  props: {
    label: String,
    btnType: String
  },
  data () {
    return {}
  },
  render (h) {
    const renderTipButton = () => {
      return h('el-button', {
        class: 'sz-button_inline',
        attrs: {
          circle: true,
          icon: 'el-icon-edit',
          ...this.$attrs
        },
        on: this.$listeners
      })
    }

    if (this.btnType === 'inline') {
      return h(
        'el-tooltip',
        {
          attrs: {
            content: this.label,
            placement: 'top',
            ...this.$attrs
          }
        },
        [renderTipButton()]
      )
    } else {
      return h(
        'el-button',
        {
          attrs: this.$attrs,
          class: 'sz-button',
          on: this.$listeners
        },
        this.label
      )
    }
  }
}
</script>
<style lang='scss' src="./auth-button.scss" scoped></style>
