var specialReg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")

export const validatePasswordInput = (r, v, c) => {
  const validateStrong = (value) => {
    let step = 0
    if (/\d/.test(value)) {
      // 数字
      step++
    }
    if (/[A-Z]/.test(value)) {
      // 大写字母
      step++
    }
    if (/[a-z]/.test(value)) {
      // 小写字母
      step++
    }
    if (specialReg.test(value)) {
      step++
    }
    return step
  }

  const validateLen = (value) => {
    return value.length < 8 || value.length > 15
  }

  if (validateLen(v)) {
    c(new Error('密码长度8-15位'))
  } else if (validateStrong(v) < 3) {
    c(new Error('密码强度不够,大写字母、小写字母、数字和符号至少包含3种'))
  } else {
    c()
  }
}
