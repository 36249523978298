
// (年月日，时分)时间处理函数
export function formatDate (date, type) {
  if (!date) {
    return '--'
  }
  var newDate = new Date(date)
  var YY = newDate.getFullYear()
  if (YY <= 1970 || isNaN(YY)) {
    return '--'
  }
  var moth = newDate.getMonth() + 1
  var MM = (moth < 10 ? '0' + moth : moth)
  var DD = (newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate())
  var hh = (newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours())
  var mm = (newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes())
  var ss = (newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds())
  if (type === 'YY-MM-DD hh:mm:ss') {
    return YY + '/' + MM + '/' + DD + ' ' + hh + ':' + mm + ':' + ss
  } else if (type === 'YY-MM-DD') {
    return YY + '/' + MM + '/' + DD
  } else if (type === 'hh:mm:ss') {
    return hh + ':' + mm + ':' + ss
  } else if (type === 'YY/MM/DD hh:mm:ss') {
    return YY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss
  } else if (type === 'YY/MM/DD') {
    return YY + '-' + MM + '-' + DD
  }
}

// 金额千分位格式化
export function amountFormat (data) {
  if (data) {
    return (data + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
  } else {
    return '--'
  }
}

// 金额格式话，全局方法
export function amountFilter (data) {
  return data.toLocaleString('en-US', { minimumFractionDigits: 2 })
}
