const _import = require('../_import_' + process.env.NODE_ENV)
export default [
  // 存续期检查报告
  {
    path: '/durationManage/durationExamineReport',
    name: 'durationExamineReport',
    component: _import('duration-manage/duration-examine-report/duration-examine-report-list'),
    meta: {
      cache: false
    }
  },
  // 贷后报告制作情况统计
  {
    path: '/durationManage/loanReportMakeStatistics',
    name: 'loanReportMakeStatistics',
    component: _import('duration-manage/loan-report-make-statistics/loan-report-make-statistics-list'),
    meta: {
      cache: false
    }
  }

]
