import axios from 'axios'
import { clearLoadingQueue, hideLoading } from './http-loading'

const pendingRequest = []
// 添加请求记录
const handlePushRequest = (config) => {
  // 生产该次请求的key值
  const requestMark = buildPendingKey(config, true)
  // 判断是否在请求中存在该次请求
  const markIndex = pendingRequest.findIndex(
    (item) => item.name === requestMark
  )
  // 如果有重复请求 取消请求
  if (markIndex > -1) {
    clearLoadingQueue(config)
    // 取消上个重复的请求
    pendingRequest[markIndex].cancel()
    // 删掉在pendingRequest中的请求标识
    pendingRequest.splice(markIndex, 1)
  }
  // 新建（重建）请求的标识
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  config.cancelToken = source.token
  config.requestMark = requestMark

  pendingRequest.push({
    instance: config,
    name: requestMark,
    cancel: source.cancel,
    routeChangeCancel: config.routeChangeCancel // 可能会有优先级高于默认设置的routeChangeCancel项值
  })

  return config
}

// 请求完成删除记录
const delPendingRequest = (config) => {
  const markIndex = pendingRequest.findIndex((item) => {
    return item.name === config.requestMark
  })
  markIndex > -1 && pendingRequest.splice(markIndex, 1)
}

// 取消剩余所有请求，在用户过期的时候处理
const cancelAllPending = () => {
  pendingRequest.forEach(item => {
    /*
      处理取消请求的loading问题
    */
    hideLoading(item.instance)

    item.cancel()
  })
}

const buildPendingKey = (config, isPending) => {
  const key = []
  const value = []
  if (isPending && config.params) {
    for (const i in config.params) {
      key.push(i)
      value.push(config.params[i])
    }
  }
  return isPending ? `${config.method} ${config.url} ${key} ${value}` : `${config.method} ${config.url}`
}

export { handlePushRequest, delPendingRequest, cancelAllPending, buildPendingKey }
