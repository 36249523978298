import Vue from 'vue'
import confirm from './state-confirm.vue'
const confirmConstructor = Vue.extend(confirm)
const theConfirm = function (content) {
  // eslint-disable-next-line promise/param-names
  return new Promise((res, rej) => {
    // promise封装，ok执行resolve，no执行rejectlet
    // eslint-disable-next-line new-cap
    const confirmDom = new confirmConstructor({
      el: document.createElement('div')
    })
    document.body.appendChild(confirmDom.$el) // new一个对象，然后插入body里面
    confirmDom.content = content // 为了使confirm的扩展性更强，这个采用对象的方式传入，所有的字段都可以根据需求自定义
    confirmDom.ok = function () {
      res()
      confirmDom.isShow = false
    }
    confirmDom.close = function () {
      // eslint-disable-next-line prefer-promise-reject-errors
      rej()
      confirmDom.isShow = false
    }
  })
}
export default theConfirm
