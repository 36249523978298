import Rest from '@/utils/http/Rest'
import http from '@/utils/http'
import { getToken } from '@/utils/auth/auth-storage'
export default class Fileapi extends Rest {
  upload () {
    return `${this.fileUrl}/api/${this.subSys}/${this.module}/upload`
  }

  /* DELETE 删除合同文件
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  deleteContract (params) {
    return http({
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete`,
      params
    })
  }

  /* post 上传文件
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  file (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/file/file',
      data
    })
  }

  /* post 批量上传
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  batchfile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/file/file/batch',
      data
    })
  }

  /**
   * 下载
   * @param {String} params 帐号
   * @returns {*|AxiosPromise} 响应
   */
  download (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/file/file/download',
      params,
      responseType: 'blob'
    })
  }

  /**
   * 访谈纪要下载
   * @param {String} params 帐号
   * @returns {*|AxiosPromise} 响应
   */
  interviewDownload (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/file/filegen/trans/file?keyIds=${data.keyIds}&transFileType=PDF`,
      data,
      responseType: 'blob'

    })
  }

  /* get 文件id下载
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  downloadKeyId (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/file/file/uri/${params}`
    })
  }

  /* get 批量下载
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  batchDownload (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/file/file/downloads',
      params,
      responseType: 'blob'
    })
  }

  /* get 通过文件id拷贝文件，返回拷贝后的文件信息
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  fileCopy (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: '/api/file/file/copy',
      params
    })
  }

  /* get 检查文件是否变更成功
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  fileSave (data) {
    return http({
      method: 'post',
      url: '/api/file/file/save',
      data
    })
  }

  /* get 在线编辑文件加锁
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  lockFile (data) {
    return http({
      method: 'post',
      url: `${process.env.VUE_APP_FILE_URL}/wopi/files/${data.fileId}?access_token=${getToken()}`,
      data,
      headers: {
        'X-WOPI-Override': 'LOCK'
      }
    })
  }
}
