import Cookies from 'js-cookie'

const TokenKey = '__WSF__token__'

const OrgIdKey = '__WSF__orgId__'

const TenantIdKey = '__WSF__tenantId'

// const operateIdKey = '__WSF__operateId'

const SysIdKey = '__WSF__sysId__'

/**
 * 获取Tenant
 * @returns {String} token
 */
export function getTenant () {
  return Cookies.get(TenantIdKey)
}

/**
 * 设置Tenant
 * @param {String} Tenant Tenant
 * @returns {Void} void
 */
export function setTenant (token) {
  return Cookies.set(TenantIdKey, token)
}

export function removeTenant () {
  return Cookies.remove(TenantIdKey)
}
/**
 * 获取token
 * @returns {String} token
 */
export function getToken () {
  return Cookies.get(TokenKey)
}

/**
 * 设置token
 * @param {String} token token
 * @returns {Void} void
 */
export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

/**
 * 移除token
 * @returns {Void} void
 */
export function removeToken () {
  removeSysId()
  removeOrgId()
  removeTenant()
  return Cookies.remove(TokenKey)
}

/**
 * 获取OrgId
 * @returns {String} OrgId
 */
export function getOrgId () {
  return Cookies.get(OrgIdKey)
}

/**
 * 设置OrgId
 * @param {String} OrgId OrgId
 * @returns {Void} void
 */
export function setOrgId (OrgId) {
  return Cookies.set(OrgIdKey, OrgId)
}

/**
 * 移除OrgId
 * @returns {Void} void
 */
export function removeOrgId () {
  return Cookies.remove(OrgIdKey)
}

/**
 * 获取SysId
 * @returns {String} SysId
 */
export function getSysId () {
  return Cookies.get(SysIdKey)
}

/**
 * 设置SysId
 * @param {String} SysId SysId
 * @returns {Void} void
 */
export function setSysId (SysId) {
  return Cookies.set(SysIdKey, SysId)
}

/**
 * 移除SysId
 * @returns {Void} void
 */
export function removeSysId () {
  return Cookies.remove(SysIdKey)
}
